import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, catchError, of } from 'rxjs';
import { BudgetService } from '../../services/budget.service';
import { GetBudgetDetailSuccessAction } from '../actions/budget-detail.action';
import {
  GetBudgetAction,
  GetBudgetSuccessAction,
} from '../actions/budget.action';

@Injectable({
  providedIn: 'root',
})
export class BudgetEffect {
  constructor(
    private actions$: Actions,
    private budgetService: BudgetService
  ) {}

  getBudget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetBudgetAction),
      exhaustMap(() =>
        this.budgetService.getBudget().pipe(
          map((budgets) => {
            return GetBudgetSuccessAction({ budgets });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  getBudgetDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetBudgetAction),
      exhaustMap(() =>
        this.budgetService.getBudgetDetail().pipe(
          map((budgetDetails) => {
            return GetBudgetDetailSuccessAction({ budgetDetails });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );
}
