import { OfflineData } from './../common/interface';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { Trackers } from '../common/enum';
import { AppErrorList } from 'src/app/common/app-constants';
import { IndexedDBService } from './indexedDb.service';

interface a {
  column: any[];
  data: any[];
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private storage: any = {};
  constructor(
    protected http: HttpClient,
    protected spinnerService: SpinnerService,
    protected indexedDb: IndexedDBService
  ) {}

  private transformArrayToArray(obj: a[]): Array<any> {
    const rows: any = [];
    // empty row object
    const objData: any = {};
    obj[0].column.map((col) => (objData[col] = ''));

    obj[0].data.forEach((rowData) => {
      const aa = { ...objData };
      Object.keys(objData).forEach((key, index) => (aa[key] = rowData[index]));

      if (aa.extraInfo && typeof aa.extraInfo === 'string') {
        try {
          aa.extraInfo = JSON.parse(aa.extraInfo);
        } catch (error) {}
      }
      rows.push(aa);
    });
    return rows;
  }

  private transformArrayToObj<T>(obj: a[]): Observable<T> {
    const rows: any = [];
    // empty row object
    const objData: any = {};
    obj[0].column.map((col) => (objData[col] = ''));

    obj[0].data.forEach((rowData) => {
      const aa = { ...objData };
      Object.keys(objData).forEach((key, index) => (aa[key] = rowData[index]));

      if (aa.extraInfo && typeof aa.extraInfo === 'string') {
        try {
          aa.extraInfo = JSON.parse(aa.extraInfo);
        } catch (error) {}
      }
      rows.push(aa);
    });
    return of(rows);
  }

  getOfflineData<T>(
    url: string,
    data?: any,
    module?: Trackers,
    arrayOnly?: boolean
  ): Observable<T> | Array<T> {
    let jsonData: any = [];
    try {
      jsonData =
        JSON.parse(
          localStorage.getItem(localStorage.getItem('userId') + '-' + url)
        ) || [];
    } catch (error) {}

    if (module === Trackers.uploadIconForTags) {
      // get the data from storage and put it in local cache
      this.setLocalData(url, jsonData);

      // updated new flag to old if there is any
      localStorage.setItem(
        localStorage.getItem('userId') + '-' + url,
        JSON.stringify(jsonData)
      );
    }

    if (arrayOnly) {
      return jsonData as Array<T>;
    }

    // return data to observable
    return of(jsonData as T);
  }

  private setLocalData(url: string, jsonData: any[]) {
    // checking if url has data stored
    // if not creating empty obj
    this.storage[url] = this.storage[url] || {};

    // if storage is empty then
    // add data to it
    let totalRecords = 0;
    jsonData.forEach((row: any) => {
      if (row) {
        if (!row.status) {
          const record = row;
          const newRecord = {
            id: row.id,
            record: { ...record },
          };
          row = newRecord;
        }

        row.status = 'old';
        this.storage[url][row.id ? row.id : row.uuid] = row;
        totalRecords++;
      }
    });
    this.storage[url].length = totalRecords;

    return jsonData;
  }

  get<T>(
    url: string,
    getOfflineData: boolean = true,
    data?: any,
    module?: Trackers,
    getAllData: boolean = false
  ): Observable<T> {
    const userId = localStorage.getItem('userId');

    // load data from local storage
    let offlineData: Observable<T> | Array<T>;
    if (getOfflineData) {
      offlineData = this.getOfflineData<T>(url, data, module, true) as [];
      if (offlineData.length == 0) {
        data = data || {};
        data.zxLQ = '(*)|(*)';
      }
    }

    if (getAllData) {
      data = data || {};
      data.zxLQ = '(*)|(*)';
    }

    //getting data from server
    return this.http
      .get<a[]>(environment.apiURL + url, {
        headers: new HttpHeaders({
          token: environment.token,
          'X-clientDate': new Date().toJSON(),
          'X-clientTimezone': (new Date().getTimezoneOffset() / 60).toString(),
        }),
        params: data,
      })
      .pipe(
        switchMap((response) => {
          if ((response && response[0] && response[0].column) == null) {
            return of(response);
          }
          return this.transformArrayToObj<T>(response).pipe(
            tap(() => {
              this.spinnerService.showSpinner--;
            }),
            map((result: any) => {
              return result;
            })
          );
        }),
        catchError((error) => {
          this.spinnerService.showSpinner--;
          return of(JSON.parse(localStorage.getItem(userId + url)) as T);
        })
      );
  }

  post<T, K>(url: string, data: T): Observable<K> {
    this.spinnerService.showSpinner++;
    return this.http
      .post<K>(environment.apiURL + url, data, {
        headers: new HttpHeaders({
          token: environment.token,
          'X-clientDate': new Date().toJSON(),
          'X-clientTimezone': (new Date().getTimezoneOffset() / 60).toString(),
        }),
      })
      .pipe(
        map((data) => {
          this.spinnerService.showSpinner--;
          try {
            let extraInfo = (data as any).extraInfo;
            if (extraInfo && typeof extraInfo === 'string') {
              extraInfo = JSON.parse((data as any).extraInfo);
            }
            if ((data as any).tags && (data as any).tags.length > 0) {
              (data as any).tagsArray = (data as any).tags.split('|');
            }
          } catch (error) {
            console.log(error);
          }
          return data;
        }),
        catchError((error) => {
          console.log(error);
          this.spinnerService.showSpinner--;
          return this.handleError(error);
        })
      );
  }

  upload(url: string, data: any, params: any): any {
    params.headers = new HttpHeaders({
      token: environment.token,
      'X-clientDate': new Date().toJSON(),
      'X-clientTimezone': (new Date().getTimezoneOffset() / 60).toString(),
    });
    return this.http.post<any>(environment.apiURL + url, data, params);
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    // const errors = this;
    // if (error.error instanceof ErrorEvent) {
    //   // A client-side or network error occurred. Handle it accordingly.
    //   console.error('An error occurred:', error.error.message);
    // } else {
    //   // The backend returned an unsuccessful response code.
    //   // The response body may contain clues as to what went wrong.
    //   console.error(
    //     `Backend returned code ${error.status}, ` + `body was: ${error.error}`
    //   );
    // }

    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error(AppErrorList[error.name] || AppErrorList.generic)
    );
  }

  getNonTrackerItem<T>(url: string, data?: any): Observable<T> {
    return this.http.get<T>(environment.apiURL + url, {
      headers: new HttpHeaders({
        token: environment.token,
        'X-clientDate': new Date().toJSON(),
        'X-clientTimezone': (new Date().getTimezoneOffset() / 60).toString(),
      }),
      params: data,
    });
  }

  getAngularJson<T>(url: string, data?: any): Observable<T> {
    return this.http.get<T>(url);
  }
}
