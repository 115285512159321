export const environment = {
  version: '0.0.1',
  production: true,
  clearCache: true,
  clearCacheCounter: 2,
  apiURL: 'https://tracker-bksa4.ondigitalocean.app/',
  doNumberIconJson: '/assets/mini-apps/expense-tracker/do-number.json',
  tagIconJson:
    'https://app-tracker.nyc3.cdn.digitaloceanspaces.com/app/tag-icons/tag-icons.json',
  memberAssets: 'https://app-tracker.nyc3.digitaloceanspaces.com/users/',
  memberIconAssetsUrl: 'https://app-tracker.nyc3.digitaloceanspaces.com/users/',
  memberReceiptsUrl: 'https://app-tracker.nyc3.digitaloceanspaces.com/users/',
  memberGroupAssetsUrl:
    'https://app-tracker.nyc3.digitaloceanspaces.com/users/',
  memberGroupTagsAssetsUrl:
    'https://app-tracker.nyc3.digitaloceanspaces.com/users/',
  iconsUrl: 'https://app-tracker.nyc3.digitaloceanspaces.com/icons/',
  appTagIconsUrl:
    'https://app-tracker.nyc3.cdn.digitaloceanspaces.com/app/tag-icons/images/',
  appIconUrl: 'https://app-tracker.nyc3.digitaloceanspaces.com/app/',
  getOfflineData: true,
  miscellaneousIcons: [
    'money.png',
    'cash.png',
    'bill.png',
    'dollar.png',
    'loonie.png',
    'others.png',
    'petty cash.png',
  ],
  token: '',
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  longMonths: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  userIcons: {},
  budgetBarColors: ['#006eb9', '#f26c52', '#22c0f1', '#007d89', '#ffcd34'],
};
