import { Injectable } from '@angular/core';
import { filter, from, map, Observable, startWith, switchMap, tap } from 'rxjs';
import { Trackers } from '../common/enum';
import { iIncome, iRecords } from '../common/interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class IncomeService extends HttpService {
  getIncome(): Observable<iIncome[]> {
    let isFromDB = false;
    return from(this.indexedDb.getAllFromDbForTable<iIncome>('income')).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((incomeFromDB: iIncome[]) => {
        return this.get<iIncome[]>(
          'income/get-income',
          false,
          undefined,
          Trackers.income,
          incomeFromDB.length === 0
        ).pipe(
          startWith(incomeFromDB),
          filter((i) => i.length > 0),
          tap((income: iIncome[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            income = structuredClone(income.filter((i) => i !== undefined));
            // only do data manipulation when its coming from server
            if (isFromDB == false && income.length > 0) {
              income = income.map((i) => {
                if (i.tagsArray === undefined && i.tags) {
                  // @ts-ignore
                  i.tagsArray = i.tags.split('|');
                }
                return i;
              });

              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('income', income);
            }
          }),
          map((j) => {
            return j.map((i) => {
              if (i.tagsArray === undefined && i.tags) {
                // @ts-ignore
                i.tagsArray = i.tags.split('|');
              }
              return i;
            });
          })
        );
      })
    );
  }

  addIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/add-income', income).pipe(
      tap((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
      })
    );
  }

  updateIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/update-income', income).pipe(
      tap((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
      })
    );
  }

  deleteIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/delete-income', income).pipe(
      tap((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
      })
    );
  }

  getExtraInfoObj(extraInfo: string) {
    try {
      return JSON.parse(extraInfo);
    } catch (error) {
      return extraInfo;
    }
  }
}
