import { EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  iTrack,
  iTrackEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import {
  AddExpensesAction,
  AddExpenseSuccessAction,
  DeleteExpenseAction,
  DeleteExpenseSuccessAction,
  GetExpensesAction,
  LoadExpensesAction,
  UpdateExpenseAction,
  UpdateExpenseSuccessAction,
} from '../../actions/expenses.action';

export const ExpensesAdaptor: EntityAdapter<iTrack> =
  createEntityAdapter<iTrack>();

const expenseReducer = createReducer(
  ExpensesAdaptor.getInitialState(),
  on(GetExpensesAction, (state) => ({ ...state })),
  on(LoadExpensesAction, (state, { tracks }) => {
    if (tracks == null) return null;
    const rows: iTrack[] = [];

    tracks.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return ExpensesAdaptor.addMany(rows, state);
    }

    return ExpensesAdaptor.upsertMany(rows, state);
  }),
  on(AddExpensesAction, (state) => ({ ...state })),
  on(AddExpenseSuccessAction, (state, { expense: tracks }) => {
    if (tracks) {
      return ExpensesAdaptor.addOne(tracks, state);
    }
    return null;
  }),
  on(UpdateExpenseAction, (state) => ({ ...state })),
  on(UpdateExpenseSuccessAction, (state, { expense: tracks }) => {
    if (tracks) {
      const updateExpense: Update<iTrack> = {
        id: tracks.id,
        changes: tracks,
      };
      return ExpensesAdaptor.updateOne(updateExpense, state);
    }
    return null;
  }),
  on(DeleteExpenseAction, (state) => ({ ...state })),
  on(DeleteExpenseSuccessAction, (state, { expense: tracks }) => {
    if (tracks) {
      const deleteExpense: Update<iTrack> = {
        id: tracks.id,
        changes: tracks,
      };
      return ExpensesAdaptor.updateOne(deleteExpense, state);
    }
    return null;
  })
);

export function ExpenseReducer(
  state: iTrackEntityState | undefined,
  action: Action
): iTrackEntityState {
  return expenseReducer(state, action);
}
