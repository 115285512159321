import { Injectable } from '@angular/core';
import { filter, from, Observable, startWith, switchMap, tap } from 'rxjs';

import { Trackers } from '../common/enum';
import { IBudget, IBudgetDetail } from '../common/interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetService extends HttpService {
  public getBudget(): Observable<IBudget[]> {
    let isFromDB = false;
    return from(this.indexedDb.getAllFromDbForTable<IBudget>('budget')).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((budgetFromDB: IBudget[]) => {
        return this.get<IBudget[]>(
          'budget/get-budget',
          false,
          undefined,
          Trackers.budget,
          budgetFromDB.length === 0
        ).pipe(
          startWith(budgetFromDB),
          filter((i) => i.length > 0),
          tap((budget: IBudget[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            budget = structuredClone(budget.filter((i) => i !== undefined));
            // only do data manipulation when its coming from server
            if (isFromDB == false && budget.length > 0) {
              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('budget', budget);
            }
          })
        );
      })
    );
  }
  public getBudgetDetail(): Observable<IBudgetDetail[]> {
    let isFromDB = false;
    return from(
      this.indexedDb.getAllFromDbForTable<IBudgetDetail>('budget_detail')
    ).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((budgetDetailFromDB) => {
        return this.get<IBudgetDetail[]>(
          'budget/get-budget-details',
          false,
          undefined,
          Trackers.budgetDetail,
          budgetDetailFromDB.length === 0
        ).pipe(
          startWith(budgetDetailFromDB),
          filter((i) => i.length > 0),
          tap((budgetDetail: IBudgetDetail[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            budgetDetail = structuredClone(
              budgetDetail.filter((i) => i !== undefined)
            );
            // only do data manipulation when its coming from server
            if (isFromDB == false && budgetDetail.length > 0) {
              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('budget_detail', budgetDetail);
            }
          })
        );
      })
    );
  }
}
