import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GroupEffects } from './mini-apps/expense-app/store/effects/group.effect';
import { IgnoreTagsEffect } from './mini-apps/expense-app/store/effects/ignore-tags.effect';
import { IncomeEffects } from './mini-apps/expense-app/store/effects/income.effect';
import { TrackEffect } from './mini-apps/expense-app/store/effects/expenses.effect';
import { GroupReducer } from './mini-apps/expense-app/store/reducers/group/group.reducer';
import { IgnoreTagsReducer } from './mini-apps/expense-app/store/reducers/ignore-tags/ignore-tags.reducer';
import { IncomeReducer } from './mini-apps/expense-app/store/reducers/income/income.reducer';
import { ExpenseReducer } from './mini-apps/expense-app/store/reducers/expenses/expenses.reducer';
import { ExpenseTrackerSettingsReducer } from './store/settings/expense-tracker/reducers/expense-tracker.reducer';
import { UserReducer } from './store/settings/user/reducers/user.reducer';
import { GroupTagReducer } from './mini-apps/expense-app/store/reducers/group-tags/group-tags.reducer';
import { GroupTagsEffects } from './mini-apps/expense-app/store/effects/group-tags.effect';
import { ModuleLoaderComponent } from './common/module-loader/module-loader.component';
import { ReoccurringTrackReducer } from './mini-apps/expense-app/store/reducers/reoccurring-tracks/reoccurring-tracks.reducer';
import { ReoccurringTrackEffect } from './mini-apps/expense-app/store/effects/reoccurring-tracks.effects';
import { IconUploadEffects } from './mini-apps/expense-app/store/effects/icon-upload.effect';
import { IconUploadReducer } from './mini-apps/expense-app/store/reducers/icon-uploads/icon-upload.reducer';
import { BudgetReducer } from './mini-apps/expense-app/store/reducers/budget/budget.reducer';
import { BudgetDetailReducer } from './mini-apps/expense-app/store/reducers/budget-details/budget-details.reducer';
import { BudgetEffect } from './mini-apps/expense-app/store/effects/budget.effect';

@NgModule({
  declarations: [AppComponent, ModuleLoaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(
      {
        groups: GroupReducer,
        income: IncomeReducer,
        trackers: ExpenseReducer,
        ignoreTags: IgnoreTagsReducer,
        groupTags: GroupTagReducer,
        expenseAppSettings: ExpenseTrackerSettingsReducer,
        user: UserReducer,
        reoccurringTracks: ReoccurringTrackReducer,
        iconUpload: IconUploadReducer,
        budget: BudgetReducer,
        budgetDetails: BudgetDetailReducer,
      },
      {}
    ),
    EffectsModule.forRoot([
      GroupEffects,
      IncomeEffects,
      TrackEffect,
      IgnoreTagsEffect,
      GroupTagsEffects,
      ReoccurringTrackEffect,
      IconUploadEffects,
      BudgetEffect,
    ]),
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'en' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
