import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Trackers } from '../../common/enum';
import { TrackService } from '../../services/track.service';
import {
  AddExpensesAction,
  AddExpenseSuccessAction,
  DeleteExpenseAction,
  DeleteExpenseSuccessAction,
  GetExpensesAction,
  LoadExpensesAction,
  UpdateExpenseAction,
  UpdateExpenseSuccessAction,
} from '../actions/expenses.action';
import { AddGroupSuccessAction } from '../actions/group.action';

@Injectable({
  providedIn: 'root',
})
export class TrackEffect {
  constructor(
    private actions$: Actions,
    private trackService: TrackService,
    private store: Store
  ) {}

  getExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetExpensesAction),
      exhaustMap(() =>
        this.trackService.getTracks(Trackers.expense).pipe(
          map((tracks) => {
            return LoadExpensesAction({ tracks });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  addExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddExpensesAction),
      switchMap((params) =>
        this.trackService.addExpenses(params.expense).pipe(
          map((expense) => {
            if (!!expense.tags) {
              expense.tagsArray = expense.tags.split('|');
            }

            const newGroup = (expense as any).newGroup;
            // new group has been added
            if (newGroup) {
              this.store.dispatch(AddGroupSuccessAction({ group: newGroup }));
            }

            return AddExpenseSuccessAction({ expense: expense });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  updateExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateExpenseAction),
      switchMap((params) =>
        this.trackService.updateExpense(params.expense).pipe(
          map((expense) => {
            if (!!expense.tags) {
              expense.tagsArray = expense.tags.split('|');
            }

            return UpdateExpenseSuccessAction({ expense });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  deleteExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteExpenseAction),
      switchMap((params) =>
        this.trackService.deleteExpense(params.expense).pipe(
          map((expense) => {
            return DeleteExpenseSuccessAction({ expense });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );
}
