import { EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IGroupTags, IGroupTagsEntityState } from '../../../common/interface';
import {
  AddGroupTagsAction,
  AddGroupTagsSuccessAction,
  DeleteGroupTagsAction,
  DeleteGroupTagsSuccessAction,
  GetGroupTagsAction,
  GetGroupTagsSuccessAction,
  UpdateGroupTagsAction,
  UpdateGroupTagsSuccessAction,
} from '../../actions/group-tags.action';

export const GroupTagAdaptors: EntityAdapter<IGroupTags> =
  createEntityAdapter<IGroupTags>();

const groupTagReducer = createReducer(
  GroupTagAdaptors.getInitialState(),
  on(GetGroupTagsAction, (state) => ({ ...state })),
  on(GetGroupTagsSuccessAction, (state, { groupTags: groupTags }) => {
    if (groupTags == null) return null;
    const rows: IGroupTags[] = [];

    groupTags.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return GroupTagAdaptors.addMany(rows, state);
    }

    return GroupTagAdaptors.upsertMany(rows, state);
  }),
  on(AddGroupTagsAction, (state) => ({ ...state })),
  on(AddGroupTagsSuccessAction, (state, { groupTag }) => {
    if (groupTag) {
      return GroupTagAdaptors.addOne(groupTag, state);
    }
    return null;
  }),
  on(UpdateGroupTagsAction, (state) => ({ ...state })),
  on(UpdateGroupTagsSuccessAction, (state, { groupTag: groupTags }) => {
    if (groupTags) {
      const updateExpense: Update<IGroupTags> = {
        id: groupTags.id,
        changes: groupTags,
      };
      return GroupTagAdaptors.updateOne(updateExpense, state);
    }
    return null;
  }),
  on(DeleteGroupTagsAction, (state) => ({ ...state })),
  on(DeleteGroupTagsSuccessAction, (state, { groupTag: groupTags }) => {
    if (groupTags) {
      const updateExpense: Update<IGroupTags> = {
        id: groupTags.id,
        changes: groupTags,
      };
      return GroupTagAdaptors.updateOne(updateExpense, state);
    }
    return null;
  })
);

export function GroupTagReducer(
  state: IGroupTagsEntityState | undefined,
  action: Action
): IGroupTagsEntityState {
  return groupTagReducer(state, action);
}
