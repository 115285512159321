import { createAction, props } from '@ngrx/store';
import { IGroupTags, iRecords } from '../../common/interface';

export enum GroupTagsActionTypes {
  GROUP_TAG_LIST_GET = 'Group Tags list GET',
  GROUP_TAG_LIST_GET_SUCCESS = 'Group Tags type list SUCCESS',
  GROUP_TAG_ADD = 'Add new Group tag',
  GROUP_TAG_ADD_SUCCESS = 'Add new Group tag success',
  GROUP_TAG_UPDATE = 'update Group tag',
  GROUP_TAG_UPDATE_SUCCESS = 'update Group tag success',
  GROUP_TAG_DELETE = 'DELETE Group tag',
  GROUP_TAG_DELETE_SUCCESS = 'delete Group tag success',
}

export const GetGroupTagsAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_LIST_GET
);

export const GetGroupTagsSuccessAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_LIST_GET_SUCCESS,
  props<{ groupTags: IGroupTags[] }>()
);

export const AddGroupTagsAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_ADD,
  props<{ groupTag: IGroupTags }>()
);

export const AddGroupTagsSuccessAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_ADD_SUCCESS,
  props<{ groupTag: IGroupTags }>()
);

export const UpdateGroupTagsAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_UPDATE,
  props<{ groupTag: IGroupTags }>()
);

export const UpdateGroupTagsSuccessAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_UPDATE_SUCCESS,
  props<{ groupTag: IGroupTags }>()
);

export const DeleteGroupTagsAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_DELETE,
  props<{ groupTag: IGroupTags }>()
);

export const DeleteGroupTagsSuccessAction = createAction(
  GroupTagsActionTypes.GROUP_TAG_DELETE_SUCCESS,
  props<{ groupTag: IGroupTags }>()
);
