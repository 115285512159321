import { createAction, props } from '@ngrx/store';
import { IExpenseTrackerSettings } from 'src/app/common/app-interfaces';

export enum IExpenseTrackerSettingsActionTypes {
    SET_SHOW_MONTH_SETTING = 'Update show month setting',
    SET_SHOW_YEAR_SETTING = 'Update show year setting',
    SET_SHOW_TOP_EXPENSES_BY_YEAR_COUNT = 'Update show no of tags for expenses tag summary by year',
    SET_SHOW_TOP_EXPENSES_BY_MONTH_COUNT = 'Update show no of tags for expenses tag summary by month'
}

export const UpdateExpenseTrackerSettings = createAction(
    IExpenseTrackerSettingsActionTypes.SET_SHOW_MONTH_SETTING,
    props<{ settings: IExpenseTrackerSettings }>()
);
