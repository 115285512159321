import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetUserInfo } from '../store/settings/user/reducers/user.selector';
import { AppNav } from './app-enum';
import { IStoreState } from './app-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<IStoreState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(GetUserInfo).pipe(
      switchMap((loggedUser) => {
        if (loggedUser && loggedUser.uuid && environment.token !== '') {
          return of(true);
        }
        this.router.navigateByUrl(AppNav.login);
        return of(false);
      })
    );
  }
}
