import { createAction, props } from '@ngrx/store';
import {
  iIgnoreTags,
  iRecords,
} from 'src/app/mini-apps/expense-app/common/interface';

export enum IgnoreTagsActionTypes {
  IGNORE_TAG_LIST_SUCCESS = 'Ignore tags list SUCCESS',
  IGNORE_TAG_LIST_GET = 'Ignore tags list GET',
  CREATE_IGNORE_TAG = 'Create ignore tag',
  CREATE_IGNORE_TAG_SUCCESS = 'Create ignore tag SUCCESS',
  UPDATE_IGNORE_TAG = 'Update ignore tag',
  UPDATE_IGNORE_TAG_SUCCESS = 'Update ignore tag SUCCESS',
}

export const GetIgnoreTagsAction = createAction(
  IgnoreTagsActionTypes.IGNORE_TAG_LIST_GET
);

export const LoadIgnoreTagsAction = createAction(
  IgnoreTagsActionTypes.IGNORE_TAG_LIST_SUCCESS,
  props<{ tags: iIgnoreTags[] }>()
);

export const CreateIgnoreTagsAction = createAction(
  IgnoreTagsActionTypes.CREATE_IGNORE_TAG,
  props<{ ignoreTag: iIgnoreTags }>()
);

export const CreateIgnoreTagsSuccessAction = createAction(
  IgnoreTagsActionTypes.CREATE_IGNORE_TAG_SUCCESS,
  props<{ tag: iIgnoreTags }>()
);

export const UpdateIgnoreTagsAction = createAction(
  IgnoreTagsActionTypes.UPDATE_IGNORE_TAG,
  props<{ ignoreTag: iIgnoreTags }>()
);

export const UpdateIgnoreTagsSuccessAction = createAction(
  IgnoreTagsActionTypes.UPDATE_IGNORE_TAG_SUCCESS,
  props<{ ignoreTag: iIgnoreTags }>()
);
