import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  iIgnoreTags,
  iIgnoreTagsEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import {
  CreateIgnoreTagsSuccessAction,
  GetIgnoreTagsAction,
  LoadIgnoreTagsAction,
  UpdateIgnoreTagsAction,
  UpdateIgnoreTagsSuccessAction,
} from '../../actions/ignore-tags.action';

export const IgnoreTagsAdaptor: EntityAdapter<iIgnoreTags> =
  createEntityAdapter<iIgnoreTags>();

const initialIgnoreTagsState: iIgnoreTagsEntityState =
  IgnoreTagsAdaptor.getInitialState();

const ignoreTagsReducer = createReducer(
  initialIgnoreTagsState,
  on(GetIgnoreTagsAction, (state) => ({ ...state })),
  on(LoadIgnoreTagsAction, (state, { tags }) => {
    if (tags == null) return null;

    const rows: iIgnoreTags[] = [];
    tags.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return IgnoreTagsAdaptor.addMany(rows, state);
    }
    return IgnoreTagsAdaptor.upsertMany(rows, state);
  }),
  on(CreateIgnoreTagsSuccessAction, (state, { tag }) =>
    IgnoreTagsAdaptor.addOne(tag, state)
  ),
  on(UpdateIgnoreTagsAction, (state) => ({ ...state })),
  on(UpdateIgnoreTagsSuccessAction, (state, { ignoreTag }) => {
    if (ignoreTag) {
      const updateExpense: Update<iIgnoreTags> = {
        id: ignoreTag.id,
        changes: ignoreTag,
      };
      return IgnoreTagsAdaptor.updateOne(updateExpense, state);
    }
    return null;
  })
);

export function IgnoreTagsReducer(
  state: iIgnoreTagsEntityState | undefined,
  action: Action
): iIgnoreTagsEntityState {
  return ignoreTagsReducer(state, action);
}
