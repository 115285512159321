import { Injectable, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Avatars } from 'src/app/common/app-interfaces';
import { environment } from 'src/environments/environment';
import { IGroupTags } from '../common/interface';
import { IconUploadActionTypes } from '../store/actions/icon-upload.action';
import { IExpenseTrackerStoreState } from '../store/reducers';
import { getAllGroupTags } from '../store/reducers/group-tags/group-tags.selector';
import { getAllGroups } from '../store/reducers/group/group.selectors';
import { getAllUserIcons } from '../store/reducers/icon-uploads/icon-upload.selectors';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  groupTags: IGroupTags[];

  groupTagsIcons: any = {};
  userIcons: any = {};
  groupIcon: any = {};

  iconsFailedToLoad: any = {};
  iconsLoadSuccess: any = {};
  licensedAppTagIcons: any = {};
  unLicensedIcons: any = {};
  private iconUrlByTagCache: any = {};
  private iconFinderCache: any = {};

  constructor(
    private actions: Actions,
    private store: Store<IExpenseTrackerStoreState>,
    private httpService: HttpService
  ) {
    // saving it for icon loading purposes, will remove later on
    (window as any).iconService = this;

    // getting icons json file
    this.cacheTagIcons();

    this.loadIconsFromStorage();

    this.prepareForCache();
    this.loadUserIcons();

    this.autoUpdateCache();
  }

  loadIconsFromStorage() {
    // get list of successful icons loaded so far
    this.iconsLoadSuccess =
      localStorage.getItem('icons-loaded-by-finder') || '{}';
    this.iconsFailedToLoad =
      localStorage.getItem('icons-loaded-failure-by-finder') || '{}';
    // parsing and saving it in an object
    this.iconsLoadSuccess = JSON.parse(this.iconsLoadSuccess);
    this.iconsFailedToLoad = JSON.parse(this.iconsFailedToLoad);

    for (const tag in this.iconsLoadSuccess) {
      const iconURL = this.iconsLoadSuccess[tag];
      this.setIconForTag(iconURL, tag);
    }
  }

  private autoUpdateCache() {
    this.actions
      .pipe(ofType(IconUploadActionTypes.CREATE_ICON_UPLOAD_SUCCESS))
      .subscribe(() =>
        setTimeout(() => {
          console.log('refreshing cache');
          this.refreshCache();
        }, 1000)
      );
  }

  prepareForCache() {
    // checking last cache done, we will do this cache every week
    let lastIconCacheDone: any = localStorage.getItem('last-icon-cache-done');
    // if lastIconCacheDone is null then initialize it true
    let startRefreshCache = lastIconCacheDone ? false : true;

    // check if we need to refresh cache
    if (lastIconCacheDone) {
      const lastDate = new Date(+lastIconCacheDone);
      const today = new Date();

      // check if last cache was done a week ago, if so refreshCache to true
      if (
        lastDate.getMonth() == today.getMonth() &&
        today.getDate() - lastDate.getDate() >= 7
      ) {
        startRefreshCache = true;
      }
    }
    // return if no need for cache
    if (startRefreshCache == false) return;
    this.refreshCache();
  }

  private refreshCache() {
    localStorage.setItem(
      'last-icon-cache-done',
      new Date().setDate(new Date().getDate()).toString()
    );

    const imageTesterContainer = document.getElementById('imageCacheRefreshed');
    const template = document.getElementById('imageCacheTemplate');
    let index = 0;
    const tags = Object.keys(this.iconsFailedToLoad);
    const imageRefreshCacheInterval = setInterval(() => {
      const newHTMLElement = document.createElement('img');
      const tag = tags[index++];
      const url = this.iconsFailedToLoad[tag];
      if (url) {
        newHTMLElement.addEventListener('load', template.onload);
        newHTMLElement.addEventListener('error', template.onerror);
        newHTMLElement.setAttribute('iconTag', tag);
        newHTMLElement.setAttribute('src', url);
        imageTesterContainer.append(newHTMLElement);
      }
      if (index >= tags.length) {
        clearInterval(imageRefreshCacheInterval);
      }
    }, 100);
  }

  loadUserIcons(): void {
    // getting user uploaded icons
    this.store.select(getAllUserIcons).subscribe((icons) => {
      const userIcons: any = {};
      icons.forEach((icon) => {
        if (icon.tag) {
          userIcons[icon.tag.toLowerCase()] =
            icon.icon || icon.iconURL || undefined;
        }
      });
      this.userIcons = userIcons;
    });

    // getting group tag icons
    this.store.select(getAllGroupTags).subscribe((groupTags) => {
      this.groupTags = groupTags;

      this.groupTags.forEach((gTags) => {
        if (gTags.isDeleted == 0 && gTags.extraInfo.tagsInfo) {
          const tags = gTags.extraInfo.tagsInfo;
          for (const key in tags) {
            this.groupTagsIcons[key.toLowerCase()] = tags[key].icon;
          }
        }
      });
    });

    this.store.select(getAllGroups).subscribe((groups) => {
      groups.forEach((group) => (this.groupIcon[group.id] = group.icon));
    });
  }

  addSuccessIcon(tag: string, url: string) {
    if (this.iconsLoadSuccess[tag]) return;
    this.iconsLoadSuccess[tag] = url;

    localStorage.setItem(
      'icons-loaded-by-finder',
      JSON.stringify(this.iconsLoadSuccess)
    );
  }

  addFailedIcon(tag: string, url: string) {
    if (this.iconsFailedToLoad[tag]) return;
    this.iconsFailedToLoad[tag] = url;
    localStorage.setItem(
      'icons-loaded-failure-by-finder',
      JSON.stringify(this.iconsFailedToLoad)
    );
  }

  cacheTagIcons() {
    this.getLicensedTagIcons();
    this.getUnLicensedIcons();
  }

  getAvatars(): Observable<Avatars[]> {
    return this.httpService.getNonTrackerItem<Avatars[]>('user/get-avatars');
  }

  private getLicensedTagIcons() {
    this.httpService
      .getAngularJson<any[]>(environment.tagIconJson)
      .subscribe(
        (response: any) => (this.licensedAppTagIcons = response.icons)
      );
  }

  private getUnLicensedIcons() {
    this.httpService
      .getAngularJson<any>(environment.doNumberIconJson)
      .subscribe((data) => (this.unLicensedIcons = data));
  }

  getGroupTagIcon(tag: string): string | undefined {
    if (this.groupTagsIcons) {
      if (
        this.groupTagsIcons[tag] !== undefined &&
        this.groupTagsIcons[tag] !== ''
      ) {
        return environment.memberGroupTagsAssetsUrl + this.groupTagsIcons[tag];
      }
    }
    return undefined;
  }

  getAppTagIcon(tag: string): string | undefined {
    if (
      this.licensedAppTagIcons &&
      this.licensedAppTagIcons[tag] !== undefined
    ) {
      if (this.licensedAppTagIcons[tag] != '') {
        return this.licensedAppTagIcons[tag];
      }
      this.licensedAppTagIcons[tag] =
        environment.appTagIconsUrl +
        tag.toLowerCase() +
        (this.licensedAppTagIcons[tag].length == 0 ? '.png' : '');
      return this.licensedAppTagIcons[tag];
    }
    return undefined;
  }

  setIconForTag(url: string, tag: string) {
    this.iconUrlByTagCache[tag] = url;
  }

  getIconForTag(tag: string) {
    return this.iconUrlByTagCache[tag];
  }

  setIconFinderCache(tags: string[], url: string) {
    this.iconFinderCache[tags.join()] = url;
  }

  getIconFinderCache(tags: string[]) {
    if (tags == undefined) return;
    return this.iconFinderCache[tags.join()];
  }
}
