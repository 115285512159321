import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, from, Observable, startWith, switchMap, tap } from 'rxjs';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { Trackers } from '../common/enum';
import { IGroupTags } from '../common/interface';
import { ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { IndexedDBService } from './indexedDb.service';

@Injectable({
  providedIn: 'root',
})
export class GroupTagsService extends HttpService {
  constructor(
    protected http: HttpClient,
    protected spinnerService: SpinnerService,
    private expenseEngine: ExpenseEngineService,
    protected indexedDb: IndexedDBService
  ) {
    super(http, spinnerService, indexedDb);
  }
  getGroupTags(trackerTypeId: number): Observable<IGroupTags[]> {
    let isFromDB = false;
    return from(
      this.indexedDb.getAllFromDbForTable<IGroupTags>('group_tags')
    ).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((groupTagsFromDB: IGroupTags[]) => {
        return this.get<IGroupTags[]>(
          'group-tags/get-group-tags',
          false,
          { trackerTypeId: trackerTypeId },
          Trackers.expense,
          groupTagsFromDB.length === 0
        ).pipe(
          startWith(groupTagsFromDB),
          filter((i) => i.length > 0),
          tap((groupTags: IGroupTags[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            groupTags = structuredClone(
              groupTags.filter((i) => i !== undefined)
            );
            // only do data manipulation when its coming from server
            if (isFromDB == false && groupTags.length > 0) {
              groupTags.forEach((groupTag) => {
                groupTag.tagsArray = groupTag.tags.split('|');
              });

              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('group_tags', groupTags);
            }

            if (groupTags.length > 0) {
              this.expenseEngine.setGroupTags(groupTags);
            }
          })
        );
      })
    );
  }

  createGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/create-group-tags',
      formData
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      })
    );
  }

  updateGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/update-group-tags',
      formData
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      })
    );
  }

  deleteGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/delete-group-tags',
      formData
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      })
    );
  }
}
