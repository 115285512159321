import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SpinnerService } from './common/spinner/spinner.service';
import { IconService } from './mini-apps/expense-app/services/icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'My Personal Tracker';
  showSpinner = false;
  destroyed$ = new Subject<void>();
  currentScreenSize: string;

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'x-small'],
    [Breakpoints.Small, 'small'],
    [Breakpoints.Medium, 'medium'],
    [Breakpoints.Large, 'large'],
    [Breakpoints.XLarge, 'x-large'],
  ]);

  constructor(
    private router: Router,
    public spinnerService: SpinnerService,
    public iconService: IconService,
    breakpointObserver: BreakpointObserver
  ) {
    // loader logic
    router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.showSpinner = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.showSpinner = false;
      }
    });

    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
