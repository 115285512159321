import { createAction, props } from '@ngrx/store';
import {
  iRecords,
  iTrack,
} from 'src/app/mini-apps/expense-app/common/interface';

export enum ExpenseActionTypes {
  EXPENSE_LIST_SUCCESS = 'Expense list SUCCESS',
  EXPENSE_LIST_GET = 'Expense list GET',
  ADD_EXPENSE = 'ADD Expense',
  ADD_EXPENSE_SUCCESS = 'ADD Expense SUCCESS',
  UPDATE_EXPENSE = 'UPDATE Expense',
  UPDATE_EXPENSE_SUCCESS = 'UPDATE Expense SUCCESS',
  DELETE_EXPENSE = 'DELETE Expense',
  DELETE_EXPENSE_SUCCESS = 'DELETE Expense SUCCESS',
}

export const GetExpensesAction = createAction(
  ExpenseActionTypes.EXPENSE_LIST_GET
);

export const LoadExpensesAction = createAction(
  ExpenseActionTypes.EXPENSE_LIST_SUCCESS,
  props<{ tracks: iTrack[] }>()
);

export const AddExpensesAction = createAction(
  ExpenseActionTypes.ADD_EXPENSE,
  props<{ expense: iTrack }>()
);

export const AddExpenseSuccessAction = createAction(
  ExpenseActionTypes.ADD_EXPENSE_SUCCESS,
  props<{ expense: iTrack }>()
);

export const UpdateExpenseAction = createAction(
  ExpenseActionTypes.UPDATE_EXPENSE,
  props<{ expense: iTrack }>()
);

export const UpdateExpenseSuccessAction = createAction(
  ExpenseActionTypes.UPDATE_EXPENSE_SUCCESS,
  props<{ expense: iTrack }>()
);

export const DeleteExpenseAction = createAction(
  ExpenseActionTypes.DELETE_EXPENSE,
  props<{ expense: iTrack }>()
);

export const DeleteExpenseSuccessAction = createAction(
  ExpenseActionTypes.DELETE_EXPENSE_SUCCESS,
  props<{ expense: iTrack }>()
);
