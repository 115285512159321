import { createAction, props } from '@ngrx/store';
import {
  iRecords,
  iReoccurringTracks,
} from 'src/app/mini-apps/expense-app/common/interface';

export enum ReoccurringTracksActionTypes {
  REOCCURRING_TRACKS_LIST_GET = 'Get Reoccurring Tracks',
  REOCCURRING_TRACKS_LIST_LOAD = 'Load Reoccurring Tracks',
  ADD_REOCCURRING_TRACKS = 'ADD Reoccurring Track',
  ADD_REOCCURRING_TRACKS_SUCCESS = 'ADD Reoccurring Track success',
  UPDATE_REOCCURRING_TRACKS = 'UPDATE Reoccurring Track',
  UPDATE_REOCCURRING_TRACKS_SUCCESS = 'UPDATE Reoccurring Track success',
  DELETE_REOCCURRING_TRACKS = 'DELETE Reoccurring Track',
  DELETE_REOCCURRING_TRACKS_SUCCESS = 'DELETE Reoccurring Track success',
}

export const GetReoccurringTracksAction = createAction(
  ReoccurringTracksActionTypes.REOCCURRING_TRACKS_LIST_GET
);

export const LoadReoccurringTracksAction = createAction(
  ReoccurringTracksActionTypes.REOCCURRING_TRACKS_LIST_LOAD,
  props<{ reoccurringTracks: iReoccurringTracks[] }>()
);

export const AddReoccurringTracksAction = createAction(
  ReoccurringTracksActionTypes.ADD_REOCCURRING_TRACKS,
  props<{ reoccurringTrack: iReoccurringTracks }>()
);

export const AddReoccurringTracksSuccessAction = createAction(
  ReoccurringTracksActionTypes.ADD_REOCCURRING_TRACKS_SUCCESS,
  props<{ reoccurringTrack: iReoccurringTracks }>()
);

export const UpdateReoccurringTracksAction = createAction(
  ReoccurringTracksActionTypes.UPDATE_REOCCURRING_TRACKS,
  props<{ reoccurringTrack: iReoccurringTracks }>()
);

export const UpdateReoccurringTracksSuccessAction = createAction(
  ReoccurringTracksActionTypes.UPDATE_REOCCURRING_TRACKS_SUCCESS,
  props<{ reoccurringTrack: iReoccurringTracks }>()
);

export const DeleteReoccurringTracksAction = createAction(
  ReoccurringTracksActionTypes.DELETE_REOCCURRING_TRACKS,
  props<{ reoccurringTrack: iReoccurringTracks }>()
);

export const DeleteReoccurringTracksSuccessAction = createAction(
  ReoccurringTracksActionTypes.DELETE_REOCCURRING_TRACKS_SUCCESS,
  props<{ reoccurringTrack: iReoccurringTracks }>()
);
