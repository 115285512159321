import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, from, Observable, startWith, switchMap, tap } from 'rxjs';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { environment } from 'src/environments/environment';
import { Trackers } from '../common/enum';
import { iGroup, iRecords } from '../common/interface';
import { ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { IndexedDBService } from './indexedDb.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends HttpService {
  constructor(
    protected http: HttpClient,
    protected spinnerService: SpinnerService,
    private expenseEngine: ExpenseEngineService,
    protected indexedDb: IndexedDBService
  ) {
    super(http, spinnerService, indexedDb);
  }

  getGroups(trackerTypeId: Trackers): Observable<iGroup[]> {
    let isFromDB = false;
    return from(this.indexedDb.getAllFromDbForTable<iGroup>('groups')).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((groupFromDB: iGroup[]) => {
        return this.get<iGroup[]>(
          'group/get-groups',
          false,
          { trackerTypeId },
          Trackers.groups,
          groupFromDB.length === 0
        ).pipe(
          startWith(groupFromDB),
          filter((i) => i.length > 0),
          tap((groups) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            groups = structuredClone(groups.filter((i) => i !== undefined));
            // only do data manipulation when its coming from server
            if (isFromDB == false && groups.length > 0) {
              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('groups', groups);
            }

            if (groups.length > 0) {
              this.expenseEngine.setGroups(groups);
            }
          })
        );
      })
    );
  }
  createGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/add-group', formData);
  }
  updateGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/update-group', formData);
  }
  deleteGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/delete-group', formData);
  }
}
