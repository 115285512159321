import { createAction, props } from '@ngrx/store';
import {
  IIconUpload,
  iRecords,
} from 'src/app/mini-apps/expense-app/common/interface';

export enum IconUploadActionTypes {
  UPLOAD_ICON_LIST_SUCCESS = 'upload icon list SUCCESS',
  UPLOAD_ICON_LIST_GET = 'upload icon list GET',
  CREATE_ICON_UPLOAD = 'Create upload icon',
  CREATE_ICON_UPLOAD_SUCCESS = 'Create upload icon SUCCESS',
  UPDATE_ICON_UPLOAD = 'Update upload icon',
  UPDATE_ICON_UPLOAD_SUCCESS = 'Update upload icon SUCCESS',
  DELETE_ICON_UPLOAD = 'Delete upload icon',
  DELETE_ICON_UPLOAD_SUCCESS = 'Delete upload icon success',
}

export const GetIconUploadAction = createAction(
  IconUploadActionTypes.UPLOAD_ICON_LIST_GET
);

export const LoadIconUploadAction = createAction(
  IconUploadActionTypes.UPLOAD_ICON_LIST_SUCCESS,
  props<{ icons: IIconUpload[] }>()
);

export const CreateIconUploadAction = createAction(
  IconUploadActionTypes.CREATE_ICON_UPLOAD,
  props<{ icon: IIconUpload }>()
);

export const CreateIconUploadSuccessAction = createAction(
  IconUploadActionTypes.CREATE_ICON_UPLOAD_SUCCESS,
  props<{ icon: IIconUpload }>()
);

export const UpdateIconUploadAction = createAction(
  IconUploadActionTypes.UPDATE_ICON_UPLOAD,
  props<{ icon: IIconUpload }>()
);

export const UpdateIconUploadSuccessAction = createAction(
  IconUploadActionTypes.UPDATE_ICON_UPLOAD_SUCCESS,
  props<{ icon: IIconUpload }>()
);

export const DeleteIconUploadAction = createAction(
  IconUploadActionTypes.DELETE_ICON_UPLOAD,
  props<{ icon: IIconUpload }>()
);

export const DeleteIconUploadSuccessAction = createAction(
  IconUploadActionTypes.DELETE_ICON_UPLOAD_SUCCESS,
  props<{ icon: IIconUpload }>()
);
