import { Injectable } from '@angular/core';
import { filter, from, Observable, startWith, switchMap, tap } from 'rxjs';
import { Trackers } from '../common/enum';
import { iReoccurringTracks } from '../common/interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ReoccurringExpensesOrIncomeService extends HttpService {
  getExpensesOrIncome(): Observable<iReoccurringTracks[]> {
    let isFromDB = false;
    return from(
      this.indexedDb.getAllFromDbForTable<iReoccurringTracks>(
        'reoccurring_tracks'
      )
    ).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((reoccurringTracksFromDB: iReoccurringTracks[]) => {
        return this.get<iReoccurringTracks[]>(
          'reoccurring-tags/get-reoccurring-tags',
          false,
          undefined,
          Trackers.reoccurringTrack,
          reoccurringTracksFromDB.length === 0
        ).pipe(
          startWith(reoccurringTracksFromDB),
          filter((i) => i.length > 0),
          tap((reoccurringTracks: iReoccurringTracks[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            reoccurringTracks = structuredClone(
              reoccurringTracks.filter((i) => i !== undefined)
            );
            // only do data manipulation when its coming from server
            if (isFromDB == false && reoccurringTracks.length > 0) {
              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk(
                'reoccurring_tracks',
                reoccurringTracks
              );
            }
          })
        );
      })
    );
  }

  addExpensesOrIncome(
    track: iReoccurringTracks
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/add-reoccurring-tags',
      track
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      })
    );
  }

  updateExpensesOrIncome(
    track: iReoccurringTracks
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/update-reoccurring-tags',
      track
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      })
    );
  }

  deleteExpensesOrIncome(
    track: iReoccurringTracks
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/delete-reoccurring-tags',
      track
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      })
    );
  }
}
