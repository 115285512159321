import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  iGroup,
  iGroupEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import { GroupAdaptor } from './group.reducer';

// get the selectors
const { selectAll, selectEntities } = GroupAdaptor.getSelectors();

export const selectGroupsFromState =
  createFeatureSelector<iGroupEntityState>('groups');
export const getAllGroups = createSelector(selectGroupsFromState, selectAll);
export const getAllGroupsEntities = createSelector(
  selectGroupsFromState,
  selectEntities
);

export const getGroupsByTrackerTypeId = (trackerTypeId: number) =>
  createSelector(getAllGroups, (groups: iGroup[]) =>
    groups.filter(
      (group) => group.trackerTypeId === trackerTypeId && group.isDeleted === 0
    )
  );

export const getGroupById = (groupId: number) =>
  createSelector(
    getAllGroupsEntities,
    (groups: Dictionary<iGroup>) => groups[groupId]
  );
