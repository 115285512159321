import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import {
  iIncome,
  iRecords,
} from 'src/app/mini-apps/expense-app/common/interface';
import { IncomeService } from '../../services/income.service';
import {
  AddIncomeAction,
  AddIncomeSuccessAction,
  DeleteIncomeAction,
  DeleteIncomeSuccessAction,
  GetIncomeAction,
  LoadIncomeAction,
  UpdateIncomeAction,
  UpdateIncomeSuccessAction,
} from '../actions/income.action';

@Injectable({
  providedIn: 'root',
})
export class IncomeEffects {
  constructor(
    private actions$: Actions,
    private incomeService: IncomeService
  ) {}

  getIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetIncomeAction),
      exhaustMap(() =>
        this.incomeService.getIncome().pipe(
          map((income: iIncome[]) => {
            return LoadIncomeAction({ income });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  addIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddIncomeAction),
      switchMap((params) =>
        this.incomeService.addIncome(params.income).pipe(
          map((income) => {
            if (!!income.tags) {
              income.tagsArray = income.tags.split('|');
            }

            return AddIncomeSuccessAction({ income: income });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  updateIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIncomeAction),
      switchMap((params) =>
        this.incomeService.updateIncome(params.income).pipe(
          map((income) => {
            if (!!income.tags) {
              income.tagsArray = income.tags.split('|');
            }

            return UpdateIncomeSuccessAction({ income });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  deleteIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteIncomeAction),
      switchMap((params) =>
        this.incomeService.deleteIncome(params.income).pipe(
          map((income) => {
            return DeleteIncomeSuccessAction({ income });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );
}
