import { createAction, props } from '@ngrx/store';
import { iRecords, IBudget } from '../../common/interface';

export enum BudgetActionTypes {
  BUDGET_LIST_GET = 'Budget list GET',
  BUDGET_LIST_GET_SUCCESS = 'Budget type list SUCCESS',
  BUDGET_ADD = 'Add new budget',
  BUDGET_ADD_SUCCESS = 'Add new budget success',
  BUDGET_UPDATE = 'update budget',
  BUDGET_UPDATE_SUCCESS = 'update budget success',
  BUDGET_DELETE = 'DELETE budget',
  BUDGET_DELETE_SUCCESS = 'delete budget success',
}

export const GetBudgetAction = createAction(BudgetActionTypes.BUDGET_LIST_GET);

export const GetBudgetSuccessAction = createAction(
  BudgetActionTypes.BUDGET_LIST_GET_SUCCESS,
  props<{ budgets: IBudget[] }>()
);

export const AddBudgetAction = createAction(
  BudgetActionTypes.BUDGET_ADD,
  props<{ budget: IBudget }>()
);

export const AddBudgetSuccessAction = createAction(
  BudgetActionTypes.BUDGET_ADD_SUCCESS,
  props<{ budget: IBudget }>()
);

export const UpdateBudgetAction = createAction(
  BudgetActionTypes.BUDGET_UPDATE,
  props<{ budget: IBudget }>()
);

export const UpdateBudgetSuccessAction = createAction(
  BudgetActionTypes.BUDGET_UPDATE_SUCCESS,
  props<{ budget: IBudget }>()
);

export const DeleteBudgetAction = createAction(
  BudgetActionTypes.BUDGET_DELETE,
  props<{ budget: IBudget }>()
);

export const DeleteBudgetSuccessAction = createAction(
  BudgetActionTypes.BUDGET_DELETE_SUCCESS,
  props<{ budget: IBudget }>()
);
