import { Action, createReducer, on } from "@ngrx/store";
import { IExpenseTrackerSettings } from "src/app/common/app-interfaces";
import { UpdateExpenseTrackerSettings } from "../actions/expense-tracker.actions";
const date = new Date();

const initialExpenseTrackerState: IExpenseTrackerSettings = {
    showExpenseMonth: date.getMonth() + 1,
    showExpenseYear: date.getFullYear(),
    showTopExpensesByYearCount: 20,
    showTopExpensesByMonthCount: 20
}

const _expenseTrackerSettingsReducer = createReducer(
    initialExpenseTrackerState,
    on(UpdateExpenseTrackerSettings, (state, { settings }) => (
        {
            showExpenseMonth: settings.showExpenseMonth,
            showExpenseYear: settings.showExpenseYear,
            showTopExpensesByMonthCount: settings.showTopExpensesByMonthCount,
            showTopExpensesByYearCount: settings.showTopExpensesByYearCount
        }
    )));

export function ExpenseTrackerSettingsReducer(state: IExpenseTrackerSettings, action: Action): IExpenseTrackerSettings {
    return _expenseTrackerSettingsReducer(state, action);
}