import { EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  IBudgetDetail,
  IBudgetDetailEntityState,
} from '../../../common/interface';
import {
  AddBudgetDetailAction,
  AddBudgetDetailSuccessAction,
  DeleteBudgetDetailAction,
  DeleteBudgetDetailSuccessAction,
  GetBudgetDetailAction,
  GetBudgetDetailSuccessAction,
  UpdateBudgetDetailAction,
  UpdateBudgetDetailSuccessAction,
} from '../../actions/budget-detail.action';

export const BudgetDetailAdaptor: EntityAdapter<IBudgetDetail> =
  createEntityAdapter<IBudgetDetail>();
const initialBudgetState: IBudgetDetailEntityState =
  BudgetDetailAdaptor.getInitialState();

const budgetDetailReducer = createReducer(
  initialBudgetState,
  on(GetBudgetDetailAction, (state) => ({ ...state })),
  on(GetBudgetDetailSuccessAction, (state, { budgetDetails }) => {
    if (budgetDetails == null) return null;
    const rows: IBudgetDetail[] = [];
    budgetDetails.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return BudgetDetailAdaptor.addMany(rows, state);
    }
    return BudgetDetailAdaptor.upsertMany(rows, state);
  }),
  on(AddBudgetDetailAction, (state) => ({ ...state })),
  on(AddBudgetDetailSuccessAction, (state, { budgetDetail }) => {
    if (budgetDetail) {
      return BudgetDetailAdaptor.addOne(budgetDetail, state);
    }
    return null;
  }),
  on(UpdateBudgetDetailAction, (state) => ({ ...state })),
  on(UpdateBudgetDetailSuccessAction, (state, { budgetDetail }) => {
    if (budgetDetail) {
      const updateBudget: Update<IBudgetDetail> = {
        id: budgetDetail.id,
        changes: budgetDetail,
      };
      return BudgetDetailAdaptor.updateOne(updateBudget, state);
    }
    return null;
  }),
  on(DeleteBudgetDetailAction, (state) => ({ ...state })),
  on(DeleteBudgetDetailSuccessAction, (state, { budgetDetail }) => {
    if (budgetDetail) {
      const deleteBudget: Update<IBudgetDetail> = {
        id: budgetDetail.id,
        changes: budgetDetail,
      };
      return BudgetDetailAdaptor.updateOne(deleteBudget, state);
    }
    return null;
  })
);

export function BudgetDetailReducer(
  state: IBudgetDetailEntityState | undefined,
  action: Action
): IBudgetDetailEntityState {
  return budgetDetailReducer(state, action);
}
