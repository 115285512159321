import { EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IBudget, IBudgetEntityState } from '../../../common/interface';
import {
  AddBudgetAction,
  AddBudgetSuccessAction,
  DeleteBudgetAction,
  DeleteBudgetSuccessAction,
  GetBudgetAction,
  GetBudgetSuccessAction,
  UpdateBudgetAction,
  UpdateBudgetSuccessAction,
} from '../../actions/budget.action';

export const BudgetAdaptor: EntityAdapter<IBudget> =
  createEntityAdapter<IBudget>();
const initialBudgetState: IBudgetEntityState = BudgetAdaptor.getInitialState();

const budgetReducer = createReducer(
  initialBudgetState,
  on(GetBudgetAction, (state) => ({ ...state })),
  on(GetBudgetSuccessAction, (state, { budgets: budgets }) => {
    if (budgets == null) return null;
    const rows: IBudget[] = [];
    budgets.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return BudgetAdaptor.addMany(rows, state);
    }
    return BudgetAdaptor.upsertMany(rows, state);
  }),
  on(AddBudgetAction, (state) => ({ ...state })),
  on(AddBudgetSuccessAction, (state, { budget }) => {
    if (budget) {
      return BudgetAdaptor.addOne(budget, state);
    }
    return null;
  }),
  on(UpdateBudgetAction, (state) => ({ ...state })),
  on(UpdateBudgetSuccessAction, (state, { budget }) => {
    if (budget) {
      const updateBudget: Update<IBudget> = {
        id: budget.id,
        changes: budget,
      };
      return BudgetAdaptor.updateOne(updateBudget, state);
    }
    return null;
  }),
  on(DeleteBudgetAction, (state) => ({ ...state })),
  on(DeleteBudgetSuccessAction, (state, { budget }) => {
    if (budget) {
      const deleteBudget: Update<IBudget> = {
        id: budget.id,
        changes: budget,
      };
      return BudgetAdaptor.updateOne(deleteBudget, state);
    }
    return null;
  })
);

export function BudgetReducer(
  state: IBudgetEntityState | undefined,
  action: Action
): IBudgetEntityState {
  return budgetReducer(state, action);
}
