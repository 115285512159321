import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Trackers } from '../common/enum';
import { iTags, iIcon, iIgnoreTags, iRecords } from '../common/interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class TagsService extends HttpService {
  tagIcons: iIcon = {};

  createIgnoreTag(tag: iIgnoreTags): Observable<iIgnoreTags> {
    return this.post<iIgnoreTags, iIgnoreTags>(
      'tags/create-ignore-tag',
      tag
    ).pipe(
      tap((ignoreTags: iIgnoreTags) => {
        if (ignoreTags) {
          this.indexedDb.upsertBulk('ignore_tags', [ignoreTags]);
        }
      })
    );
  }

  updateIgnoreTag(tag: iIgnoreTags): Observable<iIgnoreTags> {
    return this.post<iIgnoreTags, iIgnoreTags>(
      'tags/update-ignore-tag',
      tag
    ).pipe(
      tap((ignoreTags: iIgnoreTags) => {
        if (ignoreTags) {
          this.indexedDb.upsertBulk('ignore_tags', [ignoreTags]);
        }
      })
    );
  }

  addTagForIcon(tag: iTags): Observable<iTags> {
    return this.post<iTags, iTags>('tags/add-tag-for-icon', tag).pipe(
      map((tag) => {
        this.tagIcons[tag.name] = tag.iconUrl;
        return tag;
      })
    );
  }

  getIgnoreTags(): Observable<iIgnoreTags[]> {
    let isFromDB = false;
    return from(
      this.indexedDb.getAllFromDbForTable<iIgnoreTags>('ignore_tags')
    ).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((ignoreTagsFromDB: iIgnoreTags[]) => {
        return this.get<iIgnoreTags[]>(
          'tags/get-ignore-tags',
          false,
          undefined,
          Trackers.ignoreTags,
          ignoreTagsFromDB.length === 0
        ).pipe(
          startWith(ignoreTagsFromDB),
          filter((i) => i.length > 0),
          tap((ignoreTags: iIgnoreTags[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            ignoreTags = structuredClone(
              ignoreTags.filter((i) => i !== undefined)
            );
            // only do data manipulation when its coming from server
            if (isFromDB == false && ignoreTags.length > 0) {
              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('ignore_tags', ignoreTags);
            }
          })
        );
      })
    );
  }
}
