import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, from, map, Observable, startWith, switchMap, tap } from 'rxjs';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { environment } from 'src/environments/environment';
import { Trackers } from '../common/enum';
import { iRecords, iTrack } from '../common/interface';
import { Expense, ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { IndexedDBService } from './indexedDb.service';

@Injectable({
  providedIn: 'root',
})
export class TrackService extends HttpService {
  constructor(
    protected http: HttpClient,
    protected spinnerService: SpinnerService,
    protected indexedDb: IndexedDBService,
    private expenseEngine: ExpenseEngineService
  ) {
    super(http, spinnerService, indexedDb);
  }

  getTracks(trackerTypeId: number): Observable<iTrack[]> {
    let isFromDB = false;

    return from(this.indexedDb.getAllFromDbForTable<iTrack>('tracker')).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((trackersFromDB: iTrack[]) => {
        return this.get<iTrack[]>(
          'track/get-all-track-by-tracker-type',
          false,
          { trackerTypeId: trackerTypeId },
          Trackers.expense,
          trackersFromDB.length === 0
        ).pipe(
          startWith(trackersFromDB),
          filter((i) => i.length > 0),
          tap((trackers) => {
            // @ts-ignore
            trackers = structuredClone(trackers.filter((i) => i !== undefined));
            // only do data manipulation when its coming from server
            if (isFromDB == false && trackers.length > 0) {
              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('tracker', trackers);
            }
          }),
          map((j) => {
            j = j.map((i) => {
              if (i.tagsArray === undefined && i.tags) {
                // @ts-ignore
                i.tagsArray = i.tags.split('|');
              }
              return i;
            });

            this.expenseEngine.setExpenses((j || []) as Expense[]);
            return j;
          })
        );
      })
    );
  }

  addExpenses(expense: iTrack): Observable<iTrack> {
    return this.post<iTrack, iTrack>('track/add-track', expense).pipe(
      tap((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');

        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
      })
    );
  }

  updateExpense(expense: iTrack): Observable<iTrack> {
    return this.post<iTrack, iTrack>('track/update-track', expense).pipe(
      tap((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
      })
    );
  }

  deleteExpense(expense: iTrack): Observable<iTrack> {
    return this.post<iTrack, iTrack>('track/delete-track', expense).pipe(
      tap((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
      })
    );
  }

  getExtraInfoObj(extraInfo: string) {
    try {
      return JSON.parse(extraInfo);
    } catch (error) {
      return extraInfo;
    }
  }
}
