import { createAction, props } from '@ngrx/store';
import { iRecords, IBudgetDetail } from '../../common/interface';

export enum BudgetDetailActionTypes {
  BUDGET_DETAIL_LIST_GET = 'Budget Detail list GET',
  BUDGET_DETAIL_LIST_GET_SUCCESS = 'Budget Detail list SUCCESS',
  BUDGET_DETAIL_ADD = 'Add new budget Detail',
  BUDGET_DETAIL_ADD_SUCCESS = 'Add new budget success Detail',
  BUDGET_DETAIL_UPDATE = 'update budget Detail',
  BUDGET_DETAIL_UPDATE_SUCCESS = 'update budget success Detail',
  BUDGET_DETAIL_DELETE = 'DELETE budget Detail',
  BUDGET_DETAIL_DELETE_SUCCESS = 'delete budget success Detail',
}

export const GetBudgetDetailAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_LIST_GET
);

export const GetBudgetDetailSuccessAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_LIST_GET_SUCCESS,
  props<{ budgetDetails: IBudgetDetail[] }>()
);

export const AddBudgetDetailAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_ADD,
  props<{ budgetDetail: IBudgetDetail }>()
);

export const AddBudgetDetailSuccessAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_ADD_SUCCESS,
  props<{ budgetDetail: IBudgetDetail }>()
);

export const UpdateBudgetDetailAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_UPDATE,
  props<{ budgetDetail: IBudgetDetail }>()
);

export const UpdateBudgetDetailSuccessAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_UPDATE_SUCCESS,
  props<{ budgetDetail: IBudgetDetail }>()
);

export const DeleteBudgetDetailAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_DELETE,
  props<{ budgetDetail: IBudgetDetail }>()
);

export const DeleteBudgetDetailSuccessAction = createAction(
  BudgetDetailActionTypes.BUDGET_DETAIL_DELETE_SUCCESS,
  props<{ budgetDetail: IBudgetDetail }>()
);
