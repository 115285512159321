import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { TagsService } from '../../services/tags.service';
import {
  GetIgnoreTagsAction,
  LoadIgnoreTagsAction,
  CreateIgnoreTagsAction,
  CreateIgnoreTagsSuccessAction,
  UpdateIgnoreTagsAction,
  UpdateIgnoreTagsSuccessAction,
} from '../actions/ignore-tags.action';

@Injectable({
  providedIn: 'root',
})
export class IgnoreTagsEffect {
  constructor(private actions$: Actions, private tagsService: TagsService) {}

  getIgnoreTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetIgnoreTagsAction),
      exhaustMap(() =>
        this.tagsService.getIgnoreTags().pipe(
          map((tags) => LoadIgnoreTagsAction({ tags })),
          catchError((error) => of(error))
        )
      )
    )
  );

  createIgnoreTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateIgnoreTagsAction),
      exhaustMap((action) =>
        this.tagsService.createIgnoreTag(action.ignoreTag).pipe(
          map((tag) => {
            return CreateIgnoreTagsSuccessAction({ tag: tag });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  updateIgnoreTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIgnoreTagsAction),
      exhaustMap((action) =>
        this.tagsService.updateIgnoreTag(action.ignoreTag).pipe(
          map((tag) => {
            return UpdateIgnoreTagsSuccessAction({ ignoreTag: tag });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );
}
