import { Injectable } from '@angular/core';
import { filter, from, Observable, startWith, switchMap, tap } from 'rxjs';
import { Trackers } from '../common/enum';
import { IIconUpload, iIncome, iRecords } from '../common/interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class IconUploadService extends HttpService {
  getIcons(): Observable<IIconUpload[]> {
    let isFromDB = false;
    return from(
      this.indexedDb.getAllFromDbForTable<IIconUpload>('icon_uploads')
    ).pipe(
      tap((p) => {
        if (p && p.length > 0) {
          isFromDB = true;
        }
      }),
      switchMap((iconUploadFromDB: IIconUpload[]) => {
        return this.get<IIconUpload[]>(
          'icon-upload/get-icons',
          false,
          null,
          Trackers.uploadIconForTags,
          iconUploadFromDB.length === 0
        ).pipe(
          startWith(iconUploadFromDB),
          filter((i) => i.length > 0),
          tap((iconUpload: IIconUpload[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            iconUpload = structuredClone(
              iconUpload.filter((i) => i !== undefined)
            );
            // only do data manipulation when its coming from server
            if (isFromDB == false && iconUpload.length > 0) {
              // if data is coming from API then we stringify it and store it in indexed db
              this.indexedDb.upsertBulk('icon_uploads', iconUpload);
            }
          })
        );
      })
    );
  }
  createIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/add-icon',
      formData
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      })
    );
  }
  updateIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/update-icon',
      formData
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      })
    );
  }
  deleteIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/delete-icon',
      formData
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      })
    );
  }
}
