import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, catchError, of, switchMap } from 'rxjs';
import { IconUploadService } from '../../services/icon-upload.service';

import {
  CreateIconUploadAction,
  CreateIconUploadSuccessAction,
  DeleteIconUploadAction,
  DeleteIconUploadSuccessAction,
  IconUploadActionTypes,
  LoadIconUploadAction,
  UpdateIconUploadAction,
  UpdateIconUploadSuccessAction,
} from '../actions/icon-upload.action';

@Injectable({
  providedIn: 'root',
})
export class IconUploadEffects {
  constructor(
    private actions$: Actions,
    private iconUploadService: IconUploadService
  ) {}

  getIcons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IconUploadActionTypes.UPLOAD_ICON_LIST_GET),
      exhaustMap(() =>
        this.iconUploadService.getIcons().pipe(
          map((icons) => {
            return LoadIconUploadAction({ icons: icons });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  addIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateIconUploadAction),
      switchMap((param) =>
        this.iconUploadService.createIcon(param.icon).pipe(
          map((icon: any) => {
            return CreateIconUploadSuccessAction({ icon });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  updatedIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIconUploadAction),
      switchMap((param) =>
        this.iconUploadService.updateIcon(param.icon).pipe(
          map((icon: any) => {
            return UpdateIconUploadSuccessAction({ icon });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  deleteIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteIconUploadAction),
      switchMap((param) =>
        this.iconUploadService.deleteIcon(param.icon).pipe(
          map((icon: any) => {
            return DeleteIconUploadSuccessAction({ icon });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );
}
