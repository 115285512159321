export enum eFilterType {
  groupTags = 'group-tags',
  tag = 'tags',
}

export enum Trackers {
  expense = 1,
  income = 2,
  groups = 3,
  reoccurringTrack = 4,
  groupTags = 5,
  ignoreTags = 6,
  uploadIconForTags = 7,
  reports = 8,
  autoSuggestions = 9,
  budget = 10,
  budgetDetail = 11,
}

export const ModuleCacheKeys = {
  expenses: 'track/get-all-track-by-tracker-type',
  income: 'income/get-income',
  groups: 'group/get-groups',
  reoccurringTrack: 'reoccurring-tags/get-reoccurring-tags',
  groupTags: 'group-tags/get-group-tags',
  ignoreTags: 'tags/get-ignore-tags',
  uploadIconForTags: 'icon-upload/get-icons',
  budget: 'budget/get-budget',
  budgetDetail: 'budget/get-budget-details',
};

// export const

export enum ExpenseAppNav {
  home = '/expenses/home',
  expenses = '/expenses/list',
  income = '/expenses/income',
  reoccurringTrackExpense = '/expenses/reoccurring/expense',
  reoccurringTrackIncome = '/expenses/reoccurring/income',
  groups = '/expenses/groups',
  groupTags = '/expenses/group-tags',
  ignoreTags = '/expenses/ignore-tags',
  uploadIconForTags = '/expenses/upload-icon-for-tags',
  reports = '/expenses/reports',
  budget = '/expenses/budget',
  yearlyReport = '/expenses/yearly-report',
}

export enum IgnoreTagType {
  monthly,
  quarterly,
  semiannual,
  yearly,
}

export enum ExpenseTrackerModules {
  Group,
  Expense,
  Income,
  Recur,
  GroupTag,
  uploadIconsForTags,
  reports,
  ignoreTags,
}

export enum RecurringTypesEnum {
  'Every day' = <any>1,
  'Every Week' = <any>2,
  'Every 2 Weeks' = <any>3,
  'Every Month' = <any>5,
  // 'Every 4 Weeks': 4,
  // 'Every Month': 5,
  // 'Every 2 Months': 6,
  // 'Every 3 Months': 7,
  // 'Every 4 Months': 8,
  // 'Every 5 Months': 9,
  // 'Every 6 Months': 10,
  'Every Year' = <any>11,
}

export const RecurringTypes: keyNumber = {
  'Every day': <any>1,
  'Every Week': <any>2,
  'Every 2 Weeks': <any>3,
  'Every Month': 5,
  // 'Every 4 Weeks': 4,
  // 'Every Month': 5,
  // 'Every 2 Months': 6,
  // 'Every 3 Months': 7,
  // 'Every 4 Months': 8,
  // 'Every 5 Months': 9,
  // 'Every 6 Months': 10,
  'Every Year': 11,
};

interface keyNumber {
  [key: string]: number;
}

export enum BudgetType {
  yearly = 1,
  monthly = 2,
}
