import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ChartColors } from 'src/app/common/helper-functions';
import { iGroup, IGroupTags } from '../common/interface';

//#region interfaces

export interface SplitTags {
  tag: string;
  splitValue: number;
}

export interface ExpenseExtraInfo {
  iconUrl?: string;
  receiptFileName?: string;
  reOccurringId: number;
  splitTags: SplitTags[];
}

export interface ExpenseResponse {
  id: number;
  record: Expense;
  status?: 'old' | 'new';
}

export interface Expense {
  id: number;
  uuid: string;
  userId: number;
  userGroupId: number;
  trackerTypeId: number;
  trackerValue: number;
  dated: string;
  datedObj?: Date;
  groupId?: number;
  isReoccurring?: number;
  reoccurringInterval?: number;
  reoccurringDay?: number;
  tags: string;
  tagsArray: string[];
  icon?: string;
  extraInfo?: ExpenseExtraInfo;
  createdBy: number;
  createdAt: Date;
  updatedBy: number;
  updatedAt: Date;
  isDeleted: number;
  deletedBy: number;
  deletedAt: Date;
  versionNo: number;

  iconUrl?: string;
  receiptFileName?: string;
  reOccurringId: number;
  splitTags: SplitTags[];

  weight?: number;
  quantity?: number;
  rate?: number;
}

export interface TopExpenseByDay {
  day: number;
  total: number;
  expenseIds: Set<number>;
  expenses?: Expense[];
}

export interface TopExpenseByTag {
  tag: string;
  total: number;
  expenseIds: Set<number>;
  totalWeight: number;
  totalQty: number;
  expenses?: Expense[];
}

export interface TopExpenseByGroup {
  groupId: number;
  total: number;
  expenseIds: Set<number>;
  expenses?: Expense[];
}

export interface TopExpenseByGroupTag {
  groupTagId: number;
  total: number;
  expenseIds: Set<number>;
  expenses?: Expense[];
}

export interface ByGroup {
  groupId: number;
  expenseIds: Set<number>;
  total: number;
}

export interface ByTag {
  tag: string;
  total: number;
  totalQty: number;
  totalWeight: number;
  expenseIds: Set<number>;
}

export interface ByDay {
  day: number;
  total: number;
  expenseIds: Set<number>;
  summary: string;
}

export interface ByGroupTags {
  groupTagId: number;
  expenseIdsForTags: Set<number>;
  tags: Map<string, { tag: string; total: number; expenseIds: Set<number> }>;
  total: number;
}

export interface ExpenseMonthly {
  expenses: Set<number>;
  monthName: string;
  month: number;
  year: number;
  total: number;
  byDay: Map<number, ByDay>;
  byTag: Map<string, ByTag>;
  byGroup: Map<number, ByGroup>;
  byGroupTags: Map<number, ByGroupTags>;
  topExpenseByDay: Array<TopExpenseByDay>;
  topExpenseByTag: Array<TopExpenseByTag>;
  topExpenseByGroup: Array<TopExpenseByGroup>;
  topExpenseByGroupTag: Array<TopExpenseByGroupTag>;
}

export interface ExpenseYearly {
  year: number;
  total: number;
  expensesByMonth: Map<number, ExpenseMonthly>;
}

export interface ExpenseByYears {
  year: Map<number, ExpenseYearly>;
}

export interface ChartDataset {
  backgroundColor: string;
  borderColor: string;
  data: number[];
  label: string;
  tension: number;
  expenseIds: Set<number>;
  expenseSummary: Map<number, Map<number, string>>;
}
//#endregion

@Injectable({
  providedIn: 'root',
})
export class ExpenseEngineService {
  //#region properties
  private localStorageKey = 'ExpenseCache';
  // simplifying create a map for all expenses
  // id of expense is also used as map key so
  // it can be retrivable quickly since
  // its unique.
  private expenseCache = new Map<number, Expense>();
  private expenseByYears: ExpenseByYears;
  private monthNames: string[] = new Array(12)
    .fill(0)
    .map((_, i) =>
      new Intl.DateTimeFormat('default', { month: 'long' }).format(
        new Date(new Date().getFullYear(), i, 1)
      )
    );

  private currentYear = new Date().getFullYear();

  private groupTags = new Map<string, number[]>();
  private groups = new Map<number, iGroup>();
  private groupTag: IGroupTags[];
  private cachedExpenses: Expense[];

  private chartDataSet: Map<number, Map<number, Partial<ChartDataset>>>;

  //#endregion

  constructor(private currencyPipe: CurrencyPipe) {}

  prepare(userId: string) {
    // initializing groups, groupTags and overall year expense object
    this.initializeFromLocalStorage(userId);
  }

  //#region Initialize Engine methods

  setExpenses(expense: Expense[]) {
    if (expense && expense.length > 0) {
      this.AddExpenses(expense);
    }
  }

  setGroups(groups: iGroup[]) {
    if (groups && groups.length > 0) {
      groups.forEach((group) => {
        // adding group
        if (this.groups.has(group.id) == false) {
          this.groups.set(group.id, group);
        } else {
          group.isDeleted
            ? this.groups.delete(group.id)
            : this.groups.set(group.id, group);
        }
      });
    }
  }

  setGroupTags(groupTags: IGroupTags[]) {
    if (groupTags && groupTags.length > 0) {
      this.groupTag = groupTags;
      this.InitializeGroupTag();
    }
  }

  private initializeFromLocalStorage(userId: string) {
    this.LoadDataFromLocalStorageForProcessing(userId);
    this.InitializeGroupTag();
    this.InitializeExpenseByYear(this.currentYear);
  }

  private LoadDataFromLocalStorageForProcessing(userId: string) {
    const groups = JSON.parse(
      localStorage.getItem(userId + '-group/get-groups')
    ) as [];
    if (groups) {
      this.setGroups(
        groups.filter((g) => g != null).map((item: any) => item.record)
      );
    }

    const groupTags = JSON.parse(
      localStorage.getItem(userId + '-group-tags/get-group-tags')
    ) as [];
    if (groupTags) {
      this.groupTag = groupTags
        .filter((g) => g != null)
        .map((item: any) => item.record);
    }

    const cachedExpenses = JSON.parse(
      localStorage.getItem(userId + '-track/get-all-track-by-tracker-type')
    ) as [];
    if (cachedExpenses) {
      this.cachedExpenses = cachedExpenses
        .filter((g) => g != null)
        .map((item: any) => item.record);
    }
  }

  private InitializeGroupTag() {
    // a tag can be a part of many groups
    // adding tags as sets key so i can by
    // quickly fetch because we will use
    // expense.tagArray's tag value to look up
    this.groupTag?.forEach((gTag) =>
      gTag.tagsArray.forEach((tag) => {
        // getting tag from Map
        const tagObj = this.groupTags.get(tag);

        // since tag group is deleted we are removing linked tags
        if (gTag.isDeleted && tagObj) {
          // removing group id from array
          this.groupTags.set(
            tag,
            tagObj.filter((groupId) => groupId != gTag.id)
          );
        } else {
          // if tag exists add group id to it
          if (tagObj) {
            tagObj.push(gTag.id);
          } else {
            // if group tags is deleted do not set
            if (!gTag.isDeleted) {
              // if tag not exist initalize it.
              this.groupTags.set(tag, [gTag.id]);
            }
          }
        }
      })
    );
    console.log('this.groupTags', this.groupTags);
  }

  private InitializeExpenseByYear(year: number) {
    // add year to expenseByYear Map if its not initialized
    if (!this.expenseByYears?.year?.get(year)) {
      this.expenseByYears = this.expenseByYears || { year: new Map() };
      const yearlyExpense: ExpenseYearly = {
        year,
        total: 0,
        expensesByMonth: new Map<number, ExpenseMonthly>(),
      };
      this.expenseByYears.year.set(year, yearlyExpense);
    }
  }

  private InitializeMonthlyExpensesForYear(year: number, month: number) {
    // only add month if its doesn't already exist
    if (!this.expenseByYears.year.get(year)?.expensesByMonth?.get(month)) {
      const monthlyExpense: ExpenseMonthly = {
        expenses: new Set(),
        year,
        month: month,
        monthName: this.monthNames[month - 1],
        byDay: new Map(),
        byGroup: new Map(),
        byGroupTags: new Map(),
        byTag: new Map(),
        topExpenseByDay: new Array(),
        topExpenseByTag: new Array(),
        topExpenseByGroup: new Array(),
        topExpenseByGroupTag: new Array(),
        total: 0,
      };
      this.expenseByYears.year
        .get(year)
        .expensesByMonth.set(month, monthlyExpense);
    }
  }

  //#endregion

  //#region Cache logic

  private AddExpenses(expenses: Expense[]) {
    // this.LoadCacheFromLocalStorage() &&
    this.AddUpdateExpensesAndGenerateSummaryFromResponseToCache(expenses);
    this.UpdateOverAllSummaries();
    console.log('this.expenseByYears', this.expenseByYears);
  }

  private AddUpdateExpensesAndGenerateSummaryFromResponseToCache(
    expenses: Expense[]
  ) {
    try {
      expenses?.forEach((expense) => {
        const juicedUpExpense = this.addMoreToExpenseObj(expense);
        let oldExpense = this.expenseCache.get(expense.id);
        // deep copying it to avoid any referencing
        if (oldExpense) {
          oldExpense = this.addMoreToExpenseObj(
            JSON.parse(JSON.stringify(oldExpense))
          );
        }

        this.expenseCache.set(expense.id, juicedUpExpense);

        // now do extra info and populate yearly, monthly expense stats
        this.parseExpenseForStats(juicedUpExpense, oldExpense);
      });
    } catch (error) {
      console.log('error happened when generating yearly expenes');
      console.log(error);
    }
  }

  private UpdateOverAllSummaries() {
    // setting yearly tota
    this.expenseByYears?.year?.forEach((year) => {
      this.UpdateYearTotal(year);
      this.UpdateMonthlyOverAllSummeries(year);
    });
  }

  private UpdateMonthlyOverAllSummeries(year: ExpenseYearly) {
    year.expensesByMonth.forEach((monthlyExpense) => {
      monthlyExpense.topExpenseByDay = Array.from(monthlyExpense.byDay)
        .map((item) => item[1])
        .sort((a, b) => b.total - a.total)
        .map((item) => {
          return {
            day: item.day,
            total: item.total,
            expenseIds: item.expenseIds,
          };
        });

      monthlyExpense.topExpenseByGroup = Array.from(monthlyExpense.byGroup)
        .map((item) => item[1])
        .sort((a, b) => b.total - a.total)
        .map((item) => {
          return {
            groupId: item.groupId,
            total: item.total,
            expenseIds: item.expenseIds,
          };
        });

      monthlyExpense.topExpenseByGroupTag = Array.from(
        monthlyExpense.byGroupTags
      )
        .map((item) => item[1])
        .sort((a, b) => b.total - a.total)
        .map((item) => {
          return {
            groupTagId: item.groupTagId,
            total: item.total,
            expenseIds: item.expenseIdsForTags,
          };
        });

      monthlyExpense.topExpenseByTag = Array.from(monthlyExpense.byTag)
        .map((item) => item[1])
        .sort((a, b) => b.total - a.total)
        .map((item) => {
          return {
            tag: item.tag,
            total: item.total,
            totalQty: item.totalQty,
            totalWeight: item.totalWeight,
            expenseIds: item.expenseIds,
          };
        });
    });
  }

  private UpdateYearTotal(year: ExpenseYearly) {
    year.total = 0;
    year.expensesByMonth.forEach(
      (monthlyExpense) => (year.total += monthlyExpense.total)
    );
  }

  //#endregion

  //#region helper methods

  private addMoreToExpenseObj(expense: Expense): Expense {
    // converting tracker value to number
    expense.trackerValue = parseFloat(expense.trackerValue?.toString());

    // set date
    expense.datedObj = new Date(expense.dated);

    // add extra info object to root of expense object
    if (expense.extraInfo) {
      const extraInfo = expense.extraInfo;
      expense = { ...expense, ...extraInfo };
    }

    // now do some cleaning in split-tags,
    // only keep those tags whose amount
    // is different from expense amount
    if (expense.splitTags) {
      expense.splitTags
        .filter((tag) => +tag.splitValue !== expense.trackerValue)
        .forEach((splitTag) => expense.splitTags);
    }

    // work on special tags
    // convert weight and add to object
    expense.tagsArray.forEach((tag) => {
      expense.weight = this.getWeightFromTag(tag);
      expense.quantity = this.getQuantityFromTag(tag);
      expense.rate = this.getRateFromTag(tag);
    });

    return expense;
  }

  getWeightFromTag(tag: string) {
    let weightInLbs = 0;
    if (tag.indexOf('weight:') >= 0) {
      tag = tag.toLowerCase().replace('weight:', '');

      if (tag.indexOf('kg') >= 0) {
        weightInLbs =
          parseFloat(tag.replace('kg', '').replace('kgs', '')) * 2.2;
      } else {
        weightInLbs = parseFloat(tag.replace('lb', '').replace('lbs', ''));
      }
    }
    return weightInLbs;
  }

  getQuantityFromTag = (tag: string) => {
    if (tag.indexOf('qty:') >= 0) {
      return this.getTagValue(tag);
    } else return 1;
  };

  getRateFromTag = (tag: string) => {
    if (tag.indexOf('rate:') >= 0) {
      return this.getTagValue(tag);
    } else return 0;
  };

  private getTagValue(tag: string) {
    let value = parseFloat(tag.split(':')[1]);
    if (!value) {
      value = 1;
    }
    return value;
  }

  private getExpenseValueByTag(tag: string, expense: Expense) {
    let expenseValue = expense.trackerValue;
    const splitTag = expense.splitTags?.find((stag) => stag.tag == tag);
    return splitTag ? splitTag.splitValue : expenseValue;
  }

  //#endregion

  //#region Expense info extraction

  private parseExpenseForStats(expense: Expense, oldEntry?: Expense) {
    const expenseYear = expense.datedObj.getFullYear();
    const expenseMonth = expense.datedObj.getMonth() + 1;

    // initialize expense year
    this.InitializeExpenseByYear(expenseYear);

    // initialize expense month for that year
    this.InitializeMonthlyExpensesForYear(expenseYear, expenseMonth);

    const monthObj = this.expenseByYears.year
      .get(expenseYear)
      .expensesByMonth.get(expenseMonth);

    // all summaries done here
    this.updateMonthSummary(monthObj, expense, oldEntry);
    this.addToByDaySummary(monthObj, expense, oldEntry);
    this.addToByGroupSummary(monthObj, expense, oldEntry);
    this.addToByGroupTagsSummary(monthObj, expense, oldEntry);
    this.addToByTagSummary(monthObj, expense, oldEntry);

    // set chart data
    this.setChartData(monthObj, expense, oldEntry);

    // adding expense ids to month object
    monthObj.expenses.add(expense.id);
  }

  private updateMonthSummary(
    monthObj: ExpenseMonthly,
    expense: Expense,
    oldEntry: Expense
  ) {
    // subtracting old value
    if (oldEntry) {
      monthObj.total = monthObj.total - oldEntry.trackerValue;
    }

    monthObj.total += expense.trackerValue;
  }

  private addToByDaySummary(
    monthObj: ExpenseMonthly,
    expense: Expense,
    oldEntry: Expense
  ) {
    const day = expense.datedObj.getDate();

    // getting day from summary
    // if not found initialize day
    let daySummary = monthObj.byDay.get(day);
    if (!daySummary) {
      monthObj.byDay.set(day, {
        day: day,
        expenseIds: new Set(),
        total: 0,
        summary: '',
      });
      daySummary = monthObj.byDay.get(day);
    }

    // if old entry is there, remove that entry's stats
    // from object so newly can be added
    if (oldEntry) {
      daySummary.expenseIds.delete(expense.id);
      daySummary.total -= oldEntry.trackerValue;
      daySummary.summary.replace(
        `You've spend ${this.currencyPipe.transform(
          expense.trackerValue
        )} at ${expense.tagsArray.join('<br>').substring(0, 30)}...`,
        ''
      );
    }

    // if expense id is found for that day
    // then its a coming for an update
    // if not found, add it
    if (daySummary.expenseIds.has(expense.id) == false) {
      daySummary.expenseIds.add(expense.id);
      daySummary.total += expense.trackerValue;

      daySummary.summary += `You've spend ${this.currencyPipe.transform(
        expense.trackerValue
      )} at ${expense.tagsArray.join('<br>').substring(0, 30)}...`;
    }
  }

  private addToByTagSummary(
    monthObj: ExpenseMonthly,
    expense: Expense,
    oldEntry: Expense
  ) {
    expense.tagsArray.forEach((tag) => {
      let tagSummary = monthObj.byTag.get(tag);
      if (!tagSummary) {
        monthObj.byTag.set(tag, {
          expenseIds: new Set(),
          tag: tag,
          total: 0,
          totalQty: 0,
          totalWeight: 0,
        });
        tagSummary = monthObj.byTag.get(tag);
      }

      // removing old entry and its stats
      if (oldEntry) {
        tagSummary.expenseIds.delete(expense.id);
        tagSummary.total -= this.getExpenseValueByTag(tag, expense);
        tagSummary.totalQty -= oldEntry.quantity;
        tagSummary.totalWeight -= oldEntry.weight;
      }

      if (tagSummary.expenseIds.has(expense.id) == false) {
        tagSummary.expenseIds.add(expense.id);
        tagSummary.total += this.getExpenseValueByTag(tag, expense);
        tagSummary.totalQty += expense.quantity;
        tagSummary.totalWeight += expense.weight;
      }
    });
  }

  private addToByGroupTagsSummary(
    monthObj: ExpenseMonthly,
    expense: Expense,
    oldEntry: Expense
  ) {
    expense.tagsArray.forEach((tag) => {
      const tagGroupIdsForTag = this.groupTags.get(tag);

      // a tag i.e. "nadia" can be in multiple groups
      // so we get id of the group, fetch it from byGroupTags
      // find the tag in expense and add it
      tagGroupIdsForTag?.forEach((tagIG) => {
        // getting groupTag id so we can push
        // information about expense
        // if not found initialize array
        let groupExpense = monthObj.byGroupTags.get(tagIG);
        if (!groupExpense) {
          monthObj.byGroupTags.set(tagIG, {
            groupTagId: tagIG,
            expenseIdsForTags: new Set(),
            tags: new Map(),
            total: 0,
          });
          groupExpense = monthObj.byGroupTags.get(tagIG);
        }

        // now getting tag from expense group
        // each group has tags
        // each tag is maintaining how much it spend

        // starting groupTag's tag summary logic
        let groupExpenseTag = groupExpense.tags.get(tag);
        if (!groupExpenseTag) {
          groupExpense.tags.set(tag, {
            expenseIds: new Set<number>(),
            total: 0,
            tag: '',
          });
          groupExpenseTag = groupExpense.tags.get(tag);
        }

        if (oldEntry) {
          groupExpenseTag.expenseIds.delete(expense.id);
          groupExpenseTag.total -= this.getExpenseValueByTag(tag, oldEntry);
          groupExpense.total -= this.getExpenseValueByTag(tag, expense);
        }

        // just in case user added same tag twice it will not add it
        if (groupExpenseTag.expenseIds.has(expense.id) == false) {
          groupExpenseTag.expenseIds.add(expense.id);
          groupExpenseTag.total += this.getExpenseValueByTag(tag, expense);
          groupExpenseTag.tag = tag;
        }
        // ending groupTag's tag summary logic

        // now updating final groupTag obj
        // adding expense to groupTag
        groupExpense.expenseIdsForTags.add(expense.id);
        // mainting group total
        groupExpense.total += this.getExpenseValueByTag(tag, expense);
      });
    });
  }

  private addToByGroupSummary(
    monthObj: ExpenseMonthly,
    expense: Expense,
    oldEntry: Expense
  ) {
    if (expense.groupId) {
      let byGroup = monthObj.byGroup.get(expense.groupId);
      if (!byGroup) {
        monthObj.byGroup.set(expense.groupId, {
          expenseIds: new Set(),
          groupId: expense.groupId,
          total: 0,
        });
        byGroup = monthObj.byGroup.get(expense.groupId);
      }

      if (oldEntry) {
        byGroup.expenseIds.delete(expense.id);
        byGroup.total -= oldEntry.trackerValue;
      }

      const hasExpense = byGroup.expenseIds.has(expense.id);
      if (!hasExpense) {
        byGroup.expenseIds.add(expense.id);
        byGroup.total += expense.trackerValue;
        byGroup.groupId = expense.groupId;
      }
    }
  }

  private setChartData(
    monthObj: ExpenseMonthly,
    expense: Expense,
    oldEntry?: Expense
  ) {
    const day = expense.datedObj.getDate();
    const filterTags = this.getChartFiltersFromLocalStorage();

    if (!this.chartDataSet) {
      this.chartDataSet = new Map();
    }

    let yearSet = this.chartDataSet.get(monthObj.year);
    if (!yearSet) {
      this.chartDataSet.set(monthObj.year, new Map());
      yearSet = this.chartDataSet.get(monthObj.year);
    }

    let monthlyChartData = yearSet.get(monthObj.month);
    if (!monthlyChartData) {
      yearSet.set(monthObj.month, {
        label: monthObj.monthName,
        backgroundColor: ChartColors[monthObj.month],
        borderColor: ChartColors[monthObj.month],
        data: [],
        expenseIds: new Set(),
        tension: 0.2,
        expenseSummary: new Map(),
      });
      monthlyChartData = yearSet.get(monthObj.month);
    }

    // removing old entry
    if (oldEntry) {
      const oldEntryDay = oldEntry.datedObj.getDate();
      monthlyChartData.expenseIds.delete(oldEntry.id);
      // removing summary generated for that expense for that day
      monthlyChartData.expenseSummary.get(oldEntryDay)?.delete(oldEntry.id);
    }

    const tagFound =
      expense.tagsArray.filter((tag) => filterTags.includes(tag)).length > 0;

    // if tag found and its in a set, delete it
    if (tagFound) {
      if (monthlyChartData.expenseIds.has(expense.id)) {
        monthlyChartData.expenseIds.delete(expense.id);
        // removing summary generated for that expense for that day
        monthlyChartData.expenseSummary.get(day)?.delete(expense.id);
      }
    } else {
      // if tag is not found.. add expense id to an array
      monthlyChartData.expenseIds.add(expense.id);

      // generating fucking summary for chart hover tooltip
      let expenseSummary = monthlyChartData.expenseSummary.get(day);
      if (!expenseSummary) {
        monthlyChartData.expenseSummary.set(day, new Map());
        expenseSummary = monthlyChartData.expenseSummary.get(day);
      }
      expenseSummary.set(
        expense.id,
        `You spent ${(+expense.trackerValue).toFixed(2)} at ${expense.tagsArray
          .join(', ')
          .substring(0, 30)}...`
      );
    }
  }

  getChartFiltersFromLocalStorage() {
    try {
      const key = `${localStorage.getItem('userId')}-yearly-chart`;
      const filterTags = localStorage.getItem(key);
      return filterTags ? JSON.parse(filterTags) : [];
    } catch (error) {
      return undefined;
    }
  }

  setChartFiltersFromLocalStorage(items: any[]) {
    try {
      const key = `${localStorage.getItem('userId')}-yearly-chart`;
      localStorage.setItem(key, JSON.stringify(items));
    } catch (error) {}
  }

  //#endregion

  //#region getter and setters

  private getChartDataByYearWithFiltersApplied(year: number) {
    const yearObj = this.expenseByYears.year.get(year);
    this.chartDataSet = new Map();
    yearObj.expensesByMonth.forEach((monthObj) => {
      this.MapExpensesToIds(monthObj.expenses).forEach((expense) => {
        // set chart data
        this.setChartData(monthObj, expense);
      });
    });

    return this.chartDataSet.get(year);
  }

  getYearData(year: number) {
    return this.expenseByYears.year.get(year);
  }

  MapExpensesToIds(setObj: Set<number>, filterTags?: string[]) {
    return Array.from(setObj, (expenseId, _) => {
      const expense = this.expenseCache.get(expenseId);
      if (filterTags) {
        const tagFound =
          expense.tagsArray.filter((tag) => filterTags.includes(tag)).length >
          0;

        if (tagFound) return null;
      }
      return expense;
    }).filter((item) => item != null);
  }

  getChartDataByYear(year: number, newFiltersApplied = false) {
    if (newFiltersApplied) {
      return this.getChartDataByYearWithFiltersApplied(year);
    }

    const data = this.chartDataSet.get(year);
    if (data) {
      return this.chartDataSet.get(year);
    }

    return this.getChartDataByYearWithFiltersApplied(year);
  }

  getMonthIndex(month: string) {
    const name = this.monthNames.filter((item) => month == item);
    return name.length > 0 ? name[0] : -1;
  }

  getMonthNameByIndex(index: number) {
    return this.monthNames[index] || '';
  }
  //#endregion
}
