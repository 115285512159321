import { ExpenseTrackerDbTables } from './../common/interface';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { BehaviorSubject } from 'rxjs';
import ts from 'typescript';

export type Table<T> = T & {
  id?: number | string;
};

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService extends Dexie {
  private dbversion = 2;

  totalRecordToUpdate$ = new BehaviorSubject<number>(0);
  workedOnSoFar$ = new BehaviorSubject<number>(0);

  constructor() {
    super('tracker-indexed-db');
    console.log('creating db');
    this.setupTables();
  }

  async ensureTableExists(
    tableName: ExpenseTrackerDbTables,
    schema: { [tableName: string]: string | null },
    indexes?: string // optional parameter for indexes
  ): Promise<void> {
    try {
      const table = this._allTables[tableName];

      if (!table) {
        // create a schema string with indexes if provided
        let schemaString = schema[tableName];
        if (indexes) {
          schemaString += indexes;
        }
        // create a new schema object with the updated schema string
        //@ts-ignore
        let newSchema: Record<ExpenseTrackerDbTables, string | null> = {};
        newSchema[tableName] = schemaString;

        // use the new schema object when creating the table
        this.version(this.dbversion).stores(newSchema);
      }
    } catch (error) {
      console.error(`Error ensuring table "${tableName}":`, error);
      throw error;
    }
  }

  async upsertBulk<T>(tableName: ExpenseTrackerDbTables, rows: Table<T>[]) {
    try {
      const table = this.table(tableName);

      // if row array has items then add them
      await table.bulkPut(rows);
      return rows;
    } catch (error) {
      console.error('error in upsertBulk', error);
    }
    return rows;
  }

  async setupTables() {
    console.log('setting up tables');
    try {
      await this.ensureTableExists('avatars', {
        avatars: '++id,uuid,name,avatarUrl,extraInfo',
      });

      await this.ensureTableExists('budget', {
        budget:
          '++id,uuid,userId,userGroupId,budgetName,budgetYear,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,isDeletedBy,versionNo',
      });

      await this.ensureTableExists('budget_detail', {
        budget_detail:
          '++++id,userId,budgetId,tag,groupTagId,budgetType,amount,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted',
      });

      await this.ensureTableExists('group_tags', {
        group_tags:
          '++id,uuid,userId,groupName,trackerTypeId,groupIcon,tags,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,deletedAt,versionNo',
      });

      await this.ensureTableExists('groups', {
        groups:
          '++id,uuid,userId,trackerTypeId,name,icon,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,versionNo',
      });

      await this.ensureTableExists('icon_uploads', {
        icon_uploads:
          '++id,uuid,userId,tag,icon,iconURL,extraInfo,trackerTypeId,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,versionNo',
      });

      await this.ensureTableExists('income', {
        income:
          '++id,uuid,userGroupId,amount,groupId,iInterval,iDay,tags,tagsArray,isReoccurring,dated,extraInfo,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,deletedAt,versionNo',
      });

      await this.ensureTableExists('reoccurring_tracks', {
        reoccurring_tracks:
          '++id,uuid,userId,trackerTypeId,trackerValue,tags,reoccurringInterval,reoccurringDay,extraInfo,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedAt,deletedBy,versionNo',
      });

      await this.ensureTableExists('tracker', {
        tracker:
          '++id,uuid,userId,userGroupId,trackerTypeId,trackerValue,dated,groupId,isReoccurring,reoccurringInterval,reoccurringDay,tags,extraInfo,day,month,year,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,deletedAt,versionNo',
      });

      await this.ensureTableExists('tracker_permission', {
        tracker_permission:
          '++id,uuid,trackerTypeId,userId,canAdd,canUpdate,canDelete,canInvite,isActive,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,versionNo',
      });

      await this.ensureTableExists('tracker_type', {
        tracker_type:
          '++id,uuid,trackerName,trackerIcon,extraInfo,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,deletedAt,versionNo',
      });

      await this.ensureTableExists('tracker_type', {
        tracker_type:
          '++id,uuid,trackerName,trackerIcon,extraInfo,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,deletedAt,versionNo',
      });

      await this.ensureTableExists('ignore_tags', {
        ignore_tags:
          '++id,userId,tag,monthly,quarterly,semiannual,yearly,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,versionNo',
      });

      await this.ensureTableExists('user_group', {
        user_group:
          '++id,uuid,userId,groupName,icon,tag,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedAt,versionNo',
      });

      await this.ensureTableExists('user_group_members', {
        user_group_members:
          '++id,groupId,userId,extraInfo,createdBy,createdAt,updatedBy,updatedAt,deletedBy,deletedAt,versionNo',
      });

      await this.ensureTableExists('users', {
        users:
          '++id,uuid,username,password,dated,createdAt,updatedAt,isDeleted,deletedBy,lastLogin,extraInfo,versionNo',
      });

      console.log('tables created successfully');
    } catch (error) {
      console.error('error creating tables:', error);
    }
  }

  async getAllFromDbForTableByUser<T>(
    tableName: ExpenseTrackerDbTables,
    userId: string
  ): Promise<T[]> {
    const table = this.table(tableName);
    if (table) {
      return await this.table(tableName)
        .where('userId')
        .equals(userId)
        .toArray();
    }
    return [];
  }

  async getAllFromDbForTable<T>(
    tableName: ExpenseTrackerDbTables
  ): Promise<T[]> {
    const table = this.table(tableName);
    return await this.table(tableName)
      .toArray()
      .then((j) => {
        return j.map((i: any) => {
          if (i.extraInfo && typeof i.extraInfo === 'string') {
            i.extraInfo = JSON.parse(i.extraInfo);
          }
          if (i.tagsArray && typeof i.tagsArray === 'string') {
            i.tagsArray = JSON.parse(i.tagsArray as unknown as string);
          }
          return i;
        });
      });
  }

  async getAllRowsByFilters<T>(
    tableName: ExpenseTrackerDbTables,
    where: { [key: string]: any }
  ) {
    return (await this.table(tableName).where(where).toArray()) as T[];
  }

  async getRowById<T>(tableName: ExpenseTrackerDbTables, id: number) {
    return await this.table<T>(tableName).get(id);
  }
}
