import { createAction, props } from '@ngrx/store';
import { IUser } from 'src/app/common/app-interfaces';

export enum IUserActionTypes {
    SET_USER = 'set user after login',
}

export const SetUserInfo = createAction(
    IUserActionTypes.SET_USER,
    props<{ user: IUser }>()
);
