import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Trackers } from '../../common/enum';
import { GroupService } from '../../services/group.service';
import {
  LoadGroupsAction,
  GroupActionTypes,
  AddGroupAction,
  AddGroupSuccessAction,
  UpdateGroupAction,
  UpdateGroupSuccessAction,
  DeleteGroupAction,
  DeleteGroupSuccessAction,
} from '../actions/group.action';

@Injectable({
  providedIn: 'root',
})
export class GroupEffects {
  constructor(private actions$: Actions, private groupService: GroupService) {}

  getGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActionTypes.GROUP_LIST_GET),
      exhaustMap(() =>
        this.groupService.getGroups(Trackers.expense).pipe(
          map((groups) => {
            return LoadGroupsAction({ groups: groups });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  addGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddGroupAction),
      switchMap((params) =>
        this.groupService.createGroup(params.group).pipe(
          map((group: any) => {
            return AddGroupSuccessAction({ group });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateGroupAction),
      switchMap((params) =>
        this.groupService.updateGroup(params.group).pipe(
          map((group: any) => {
            return UpdateGroupSuccessAction({ group });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );

  deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteGroupAction),
      switchMap((params) =>
        this.groupService.deleteGroup(params.group).pipe(
          map((group: any) => {
            return DeleteGroupSuccessAction({ group });
          }),
          catchError((error) => of(error))
        )
      )
    )
  );
}
