import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  iIncome,
  iIncomeEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import {
  AddIncomeAction,
  AddIncomeSuccessAction,
  DeleteIncomeAction,
  DeleteIncomeSuccessAction,
  GetIncomeAction,
  LoadIncomeAction,
  UpdateIncomeAction,
  UpdateIncomeSuccessAction,
} from '../../actions/income.action';

export const IncomeAdaptor: EntityAdapter<iIncome> =
  createEntityAdapter<iIncome>();

const initialIncomeState = IncomeAdaptor.getInitialState();

const incomeReducer = createReducer(
  initialIncomeState,
  on(GetIncomeAction, (state) => ({ ...state })),
  on(LoadIncomeAction, (state, { income }) => {
    if (income == null) return null;

    const rows: iIncome[] = [];

    income.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return IncomeAdaptor.addMany(rows, state);
    }

    return IncomeAdaptor.upsertMany(rows, state);
  }),
  on(AddIncomeAction, (state) => ({ ...state })),
  on(AddIncomeSuccessAction, (state, { income: income }) => {
    if (income) {
      return IncomeAdaptor.addOne(income, state);
    }
    return null;
  }),
  on(UpdateIncomeAction, (state) => ({ ...state })),
  on(UpdateIncomeSuccessAction, (state, { income: income }) => {
    if (income) {
      const updateIncome: Update<iIncome> = {
        id: income.id,
        changes: income,
      };
      return IncomeAdaptor.updateOne(updateIncome, state);
    }
    return null;
  }),
  on(DeleteIncomeAction, (state) => ({ ...state })),
  on(DeleteIncomeSuccessAction, (state, { income: income }) => {
    if (income) {
      const deleteIncome: Update<iIncome> = {
        id: income.id,
        changes: income,
      };
      return IncomeAdaptor.updateOne(deleteIncome, state);
    }
    return null;
  })
);

export function IncomeReducer(
  state: iIncomeEntityState | undefined,
  action: Action
): iIncomeEntityState {
  return incomeReducer(state, action);
}
