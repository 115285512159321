import { Action, createReducer, on } from '@ngrx/store';
import { IUser } from 'src/app/common/app-interfaces';
import { SetUserInfo } from '../actions/user.actions';

const initialUserState: IUser = {
  id: undefined,
  uuid: undefined,
  username: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  isDeleted: undefined,
  deletedBy: undefined,
  lastLogin: undefined,
  extraInfo: undefined,
};

const _UserReducer = createReducer(
  initialUserState,
  on(SetUserInfo, (state, { user }) => ({
    id: user.id,
    uuid: user.uuid,
    username: user.username,
    createdAt: user.createdAt,
    deletedBy: user.deletedBy,
    extraInfo: user.extraInfo,
    isDeleted: user.isDeleted,
    lastLogin: user.lastLogin,
    updatedAt: user.updatedAt,
  }))
);

export function UserReducer(state: IUser, action: Action): IUser {
  return _UserReducer(state, action);
}
